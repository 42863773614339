import React from 'react';
import {ChartingVariable, CompressorAlert} from "../../../autogenerate/api.generated.clients";
import {LineChart, LineSeriesType} from "@mui/x-charts";
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import './LineChart.scss';
import {MakeOptional} from "@mui/x-charts/models/helpers";
import AlertAnnotation from '../AlertAnnotation/AlertAnnotation';
import {convertDateStringToLocalTime} from "../../../utilities/dateHelper";

interface GraphPointDate {
    x: Date;
    y: number | null;
}

export interface Series {
    friendlyName: string,
    dataPoints: GraphPointDate[],
    propertyName: string
}

interface LineChartProps {
    leftAxisVariables: ChartingVariable[],
    rightAxisVariables?: ChartingVariable[],
    showDigitalTwin? :boolean | undefined,
    alerts?: CompressorAlert[],
    leftAxisLabel?: string
    rightAxisLabel?: string,
    showMark?: boolean
}

export function getSeries(variables: ChartingVariable[], showDigitalTwin: boolean | undefined) : Series[] {
    const series = variables.flatMap(variable=> {
        let arr =
            [
                {
                    friendlyName: `${variable.displayName} (${variable.unitOfMeasure})`,
                    dataPoints: variable.graphDataPoints.map( dataPoint => {
                        return {x: new Date(dataPoint.x ?? ""), y: dataPoint.y} as GraphPointDate
                    }),
                    propertyName: variable.ascPropertyName
                } as Series
            ]
        if(variable.digitalTwin && showDigitalTwin)
            arr.push(
                {
                    friendlyName: `${variable.digitalTwin?.displayName} (${variable.digitalTwin.unitOfMeasure})`,
                    dataPoints: variable.digitalTwin.graphDataPoints.map( dataPoint => {
                        return {x: new Date(dataPoint.x ?? ""), y: dataPoint.y} as GraphPointDate
                    }),
                    propertyName: variable.digitalTwin.ascPropertyName
                } as Series);
        return arr;
    });

    return series;
}

export function addMissingPoints(series: Series[], lengths: number[]) : Series[] {
    const combinedSeriesDataPoints = series.flatMap(el => el.dataPoints);
    const uniqueSeriesDataPoints = Array.from(new Set(combinedSeriesDataPoints.map(point => point.x.valueOf())));
    const maxEpochSeconds = 8640000000000000;

    for(let i = 0; i < uniqueSeriesDataPoints.length; i++)
    {
        const dates = series.map(point=> new Date(point.dataPoints[i]?.x ?? maxEpochSeconds).valueOf());
        const soonest = Math.min(...dates);

        series.map(x => {
            if(new Date(x.dataPoints[i]?.x ?? maxEpochSeconds).valueOf() > soonest)
            {
                const missingPoint = {
                    x: new Date(soonest),
                    y: null
                } as GraphPointDate;

                x.dataPoints.splice(i, 0, missingPoint);

            }
        })
    }

    return series;
}

export function getYAxisMin(series: Series[]) {
    const dataPointValues = series.flatMap(series => series.dataPoints.map(point => point.y ?? 0)).sort((a, b) => {return a - b});
    const lowestSeriesValue = dataPointValues.length > 0 ? dataPointValues[0] : 0;
    return lowestSeriesValue < 0 ? lowestSeriesValue - 10 : 0;
}

export function LineChartMultiYAxis(props: LineChartProps) {
    const {leftAxisVariables, rightAxisVariables, showDigitalTwin, alerts, leftAxisLabel, rightAxisLabel, showMark} = props;
    let combinedSeries = [ ...getSeries(leftAxisVariables, showDigitalTwin), ...getSeries(rightAxisVariables ?? [], showDigitalTwin)]
    const lengths = combinedSeries.map(x=> x!.dataPoints.length);
    const totalWithMissingPoings = addMissingPoints(combinedSeries, lengths);
    const leftSeries = totalWithMissingPoings.filter(s=> leftAxisVariables.some(v => v.ascPropertyName === s.propertyName || (showDigitalTwin && v.digitalTwin?.ascPropertyName === s.propertyName) ));
    const rightSeries = totalWithMissingPoings.filter(s=> rightAxisVariables?.some(v => v.ascPropertyName === s.propertyName || (showDigitalTwin && v.digitalTwin?.ascPropertyName === s.propertyName)));
    return (
        <LineChart
            grid={{vertical: true, horizontal: true}}
            xAxis={[{
                id: 'timestamps',
                data: leftSeries[0]?.dataPoints.map(point => point.x.valueOf())
                     ?? rightSeries[0]?.dataPoints.map(point => point.x.valueOf()),
                valueFormatter: (value) => convertDateStringToLocalTime(value)
            }]}
            yAxis={[
                {
                    id: 'leftAxis',
                    label: leftAxisLabel ? leftAxisLabel : '',
                    scaleType: "linear",
                    min: getYAxisMin(leftSeries)
                },
                {
                    id: 'rightAxis',
                    label: rightAxisLabel ? rightAxisLabel : '',
                    scaleType: "linear",
                    min: getYAxisMin(rightSeries)
                }
            ]}
            series={[...leftSeries?.map(series => ({
                yAxisKey: 'leftAxis',
                id: series.propertyName,
                data: series.dataPoints.map(point => point.y),
                label: series.friendlyName,
                showMark: showMark ? showMark : false,
                curve: "linear",
                valueFormatter: (value: any) => value == null ? "Value not received" : value.toString()
            } as MakeOptional<LineSeriesType, any> )),
                ...rightSeries?.map(series => ({
                    yAxisKey: 'rightAxis',
                    id: series.propertyName,
                    data: series.dataPoints.map(point => point.y),
                    label: series.friendlyName,
                    showMark: showMark ? showMark : false,
                    curve: "linear",
                    valueFormatter: (value: any) => value == null ? "Value not received" : value.toString()
                } as MakeOptional<LineSeriesType, any> )),
            ]}
            slotProps={{
                legend: {
                    labelStyle: {
                        fontSize: 12,
                    },
                    position: {vertical: 'bottom', horizontal: 'middle'},
                    direction: 'row',
                    itemMarkWidth: 15,
                    itemMarkHeight: 2,
                    markGap: 4,
                    itemGap: 7,
                }
            }}
            margin={{bottom: 150}}
            bottomAxis={{
                axisId: "timestamps",
                tickLabelStyle: {
                    angle: 45,
                    textAnchor: "start",
                    fontSize: 10
                }
            }}
            leftAxis={leftSeries.length ? {
                axisId: 'leftAxis',
                labelStyle: {
                    fontSize: 12
                }
            } : undefined}
            rightAxis={rightSeries.length ? {
                axisId: 'rightAxis',
                labelStyle: {
                    fontSize: 12
                }
            } : undefined}
            sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: 'translate(-13px, 0)',
                },
                [`.${axisClasses.right} .${axisClasses.label}`]: {
                    transform: 'translate(13px, 0)',
                },
            }}
        >
            
            {alerts?.map((alert, index)=> 
                <AlertAnnotation alert={alert}/>) ?? ''
            }
        </LineChart>
    );
}

