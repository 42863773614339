import React from 'react';
import {ChartingVariable} from "../../../autogenerate/api.generated.clients";
import {Button, Grid, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import './chartAxisChooser.scss'

interface ChartAxisChooserProps {
    leftAxisVariables: readonly ChartingVariable[],
    rightAxisVariables: readonly ChartingVariable[],
    onUpdateLeftAxisVariables: (variables: ChartingVariable[]) => void
    onUpdateRightAxisVariables: (variables: ChartingVariable[]) => void
};

function not(a: readonly ChartingVariable[], b: readonly ChartingVariable[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly ChartingVariable[], b: readonly ChartingVariable[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function ChartAxisChooser(props: ChartAxisChooserProps) {
    const {leftAxisVariables, rightAxisVariables} = props;
    const [checked, setChecked] = React.useState<readonly ChartingVariable[]>([]);
    const leftChecked = intersection(checked, leftAxisVariables);
    const rightChecked = intersection(checked, rightAxisVariables);
    const handleToggle = (value: ChartingVariable) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const customList = (items: readonly ChartingVariable[]) => (
        <List dense component="div" role="list">
            {items.map((item: ChartingVariable) => {
                const labelId = `transfer-list-item-${item.ascPropertyName}-label`;

                return (
                    <ListItemButton
                        selected={checked.indexOf(item) !== -1}
                        key={item.ascPropertyName}
                        role="listitem"
                        onClick={handleToggle(item)}
                    >
                        <ListItemText id={labelId} primary={item.displayName}/>
                    </ListItemButton>
                );
            })}
        </List>
    );

    const handleCheckedRight = () => {
        props.onUpdateRightAxisVariables(rightAxisVariables.concat(leftChecked));
        props.onUpdateLeftAxisVariables(not(leftAxisVariables, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        props.onUpdateLeftAxisVariables(leftAxisVariables.concat(rightChecked));
        props.onUpdateRightAxisVariables(not(rightAxisVariables, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    return (
        <div className='axis-chooser'>
            <div className='axis-chooser-header'>
                <Typography>Left Y-Axis</Typography>
                <Typography>Right Y-Axis</Typography>
            </div>
            <div className='list-container'>
                <div className={'transfer-list'} data-testid={'left-list'}>{customList(leftAxisVariables)}</div>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{my: 0.5}}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{my: 0.5}}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <div className={'transfer-list'} data-testid={'right-list'}>{customList(rightAxisVariables)}</div>
            </div>
        </div>
    );
}

export default ChartAxisChooser;