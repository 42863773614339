import {MessageComponent} from "@syncfusion/ej2-react-notifications";

interface UserMessageProps {
    messageContent: string,
    messageSeverity: string

    displayMessage: boolean

    toggleDisplayMessage: () => void
}

export function UserMessage(props: UserMessageProps) {
    const {messageContent, messageSeverity, displayMessage, toggleDisplayMessage} = props;
    return (
        <div style={{display: displayMessage ? "" : 'none', margin: '1em'}}>
            <MessageComponent id={`msg_${messageSeverity.toLowerCase()}`} content={messageContent}
                              severity={messageSeverity} visible={displayMessage} showCloseIcon
                              onClick={toggleDisplayMessage}/>
        </div>
    )
}