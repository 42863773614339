import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import "./DataExport.scss";
import {utils, writeFile} from 'xlsx';
import {ChartingVariable} from "../../autogenerate/api.generated.clients";
import {useChartingDataExport} from "../../hooks/Compressor/Reports/Charting/useChartingDataExport";


interface DataExportProps {
    data: ChartingVariable[],
    reportTitle: string,
}

export function DataExport(props: DataExportProps) {
    const {data, reportTitle} = props;
    const getChartData = useChartingDataExport();
    const OnExport = async () => {
        //Format dates to be in local time
        data.forEach((variable) => {
            if(variable.digitalTwin){
                variable.digitalTwin.graphDataPoints.forEach((dataPoint) => {
                    if(dataPoint.x){
                        dataPoint.x = new Date(dataPoint.x).toLocaleDateString('en-US', {
                            hour12: true,
                            year: '2-digit',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        })}
                })
                }
            variable.graphDataPoints.forEach((dataPoint) => {
                if(dataPoint.x){
                dataPoint.x = new Date(dataPoint.x).toLocaleDateString('en-US', {
                    hour12: true,
                    year: '2-digit',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                })};
            })
        })
        let result = await getChartData.chartData(data);
        result.sort((a: any, b: any) => new Date(a["TimeStamp"]).valueOf() - new Date(b["TimeStamp"]).valueOf())
        let workbookName = reportTitle + '.xlsx';
        let workbook = utils.book_new();
        if(result.length > 0) {
        let worksheet = utils.json_to_sheet(result);
        utils.book_append_sheet(workbook, worksheet);
        writeFile(workbook, workbookName);
        }
    }
    
    if(data.length == 0) {
        return <div/>
    }

    return (
        <div>
            <ButtonComponent className="data-export-button" isPrimary onClick={OnExport}>Export</ButtonComponent>
        </div>
    )
}