import {useQuery} from "react-query";
import {Client} from "../../autogenerate/api.generated.clients";


function UseTermsAndConditions() {
    return useQuery(["termsAndConditions"], () => new Client().termsAndConditions_GetTermsAndConditions(), {
        refetchOnWindowFocus: false,
        staleTime: 600000
    });
}

export default UseTermsAndConditions;