import React from "react";
import {Link, Typography} from "@mui/material";
import "./ExternalNavLinks.scss";

export interface ExternalNavLinksProps {
    linkDescription?: string,
    links: Link[]
}

export interface Link {
    displayName: string,
    linkAddress: string
}
export default function ExternalNavLinks(props: ExternalNavLinksProps) {
    const {linkDescription, links} = props;

    const LinkHeader = () => {
        if(linkDescription) {
            return <Typography variant="body1">{linkDescription}</Typography>
        } else {
            return <div/>
        }
    }

    return (
        <div className="nav-links-container">
            <LinkHeader/>
            {links.map((link,index) => {
                return (
                    <Link
                        key={index}
                        href={link.linkAddress}
                        target="_blank"
                        underline="hover"
                        variant="body1"
                        className="nav-link"
                    >
                        {link.displayName}
                    </Link>
                )
            })}
        </div>
    )
}