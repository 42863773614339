export enum AlertResolutionType {
    Resolved = "resolved",
    Active = "unresolved"
}

export enum FlowDirection {
    Suction = 0,
    Discharge = 1
}

export enum ValveLeakSeverity {
    Unknown = "Unknown",
    Normal = "Normal (< 0.5)",
    Moderate = "Moderate (0.5 - 1.0)",
    Critical = "Critical (> 1.0)"
}

export enum UnloaderType {
    VVCP,
    FVCP,
    EVCP
}

export enum CylinderPosition {
    CE,
    HE,
    DoubleActing
}

export enum Periodicity {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly"
}