import React, {useState} from 'react';
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import './phoneNumberValidationView.scss'
import {Button, TextField, Typography} from "@mui/material";
import {Client} from "../../autogenerate/api.generated.clients";

interface PhoneNumberValidationViewProps {
    onSuccess?: Function,
    onInputCancel: Function
}

function PhoneNumberValidationView(props: PhoneNumberValidationViewProps) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationFailed, setShowVerificationFailed] = useState(false);
    const onSubmitClicked = () => {
        switch (activeStep) {
            case 0:
                if (matchIsValidTel(phoneNumber)) {
                    startVerification();
                    setActiveStep(1);
                }
                break;
            case 1:
                submitVerification();
        }
    }
    const submitVerification = async () => {
        try {
            var response = await new Client().notificationPreferences_UpdatePhoneNumber({
                phoneNumber,
                verificationCode
            });
            if (props.onSuccess) {
                props.onSuccess();
            }
        } catch (exception) {
            setShowVerificationFailed(true)
        }
    }
    const onBackClicked = () => {
        if (activeStep > 0)
            setActiveStep(activeStep - 1)
    }
    const startVerification = () => {
        new Client().verificationCode_SendVerification(phoneNumber);
    }
    const onVerificationTextFieldChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode(e.target.value);
        setShowVerificationFailed(false);
    }

    return (
        <div className='phone-validation-view'>

            {activeStep == 0
                ? <div className='step-container'>
                    <Typography variant={'h6'}>Enter Phone Number</Typography>
                    <MuiTelInput
                        defaultCountry={'US'}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                    />
                    {!matchIsValidTel(phoneNumber)
                        ? <Typography color={'error'}>Please enter a valid phone number</Typography>
                        : ''
                    }
                </div>
                : ''
            }
            {activeStep == 1
                ? <div className='step-container'>
                    <Typography variant={'h6'}>Enter Validation Code</Typography>
                    <TextField autoFocus
                               value={verificationCode}
                               onChange={onVerificationTextFieldChanged}/>
                    {showVerificationFailed
                        ? <Typography color={'error'}>Verification failed</Typography>
                        : ''
                    }
                </div>
                : ''
            }
            <div className='buttons-container'>
                {activeStep > 0
                    ? <Button onClick={(e) => onBackClicked()}>
                        Back</Button>
                    : <Button onClick={(e) => props.onInputCancel()}>
                        Cancel
                    </Button>
                }
                <Button className='submit-button'
                        onClick={(e) => onSubmitClicked()}>
                    Submit
                </Button>
            </div>
        </div>
    );
}

export default PhoneNumberValidationView;