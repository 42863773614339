import React from 'react';
import './DashboardCard.scss';
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import ValueNotReceivedMessage from "../ValueNotReceivedMessage/ValueNotReceivedMessage";

export interface DashboardCardProps extends React.HTMLProps<HTMLDivElement>{
    label: string,
    value: string | number,
    isLoading?: boolean | undefined,
    cardClassName?: string
}

export interface LabelValuePair {
    label: string
    value: string | number
}

export function DashboardCard(props: DashboardCardProps) {
    const {label, value, isLoading, cardClassName, ...rest} = props;
    return (
        <div {...rest} className={`e-card flex-sm dashboard-card ${rest.className ?? ""}`}>
            <div className={`value-prop-container e-card-content `}>
                <span>{label}</span>
                {isLoading
                    ? <SkeletonComponent style={{height: '2em', width: '60%'}}/>
                    : !value.toString().includes("null") && !value.toString().includes("undefined")
                        ? <span className={`value-prop-value ${cardClassName ?? ""}`}>{value}</span>
                        : <ValueNotReceivedMessage/>
                }
            </div>
        </div>
    );
}