import React, {useEffect} from 'react';
import {CompressorLubeOilCards} from "./CompressorLubeOilCards/CompressorLubeOilCards";
import {CompressorLubeOilChart} from "./CompressorLubeOilChart/CompressorLubeOilChart";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";


export default function LubeOilTab() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber;

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/compressors/${frameSerialNumber}/lube`, title: "Compressor Lube Oil" });
    }, []);
    return (
        <div>
            <CompressorLubeOilCards frameSerialNumber={frameSerialNumber!}/>
            <CompressorLubeOilChart frameSerialNumber={frameSerialNumber!}/>
        </div>
    );
}