import {useMutation, UseMutationResult, useQuery, useQueryClient} from "react-query";
import {Client, DefaultNotificationDTO} from "../../autogenerate/api.generated.clients";
import {useState} from "react";
import {AlertColor} from "@mui/material";
import {UseQueryResult} from "react-query/types/react/types";
import {AlertInfo} from "../../components/AlertUser/AlertUser";

export interface UseNotificationRulesForCompressorProps {
    frameSerialNumber: string
    userEmail: string
}

export type MutateProps = {
    notificationToUpdate: DefaultNotificationDTO,
    isEmailEnabled: boolean,
    isSmsEnabled: boolean,
}

export type UpdateNotification = (valuesToUpdate: {
    isSmsEnabled: boolean,
    isEmailEnabled: boolean
}) => void;

export function useNotificationRulesForCompressor(props: UseNotificationRulesForCompressorProps):
    [UseQueryResult<DefaultNotificationDTO[], unknown>, UseMutationResult<DefaultNotificationDTO, unknown, MutateProps>, AlertInfo] {
    const {frameSerialNumber, userEmail} = props;

    const queryKey = ['compressor_notification_rules', frameSerialNumber];

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState<AlertColor>("success");

    const queryClient = useQueryClient();

    const query = useQuery(queryKey,
        () => new Client().defaultNotification_GetDefaultNotificationsForCompressor(frameSerialNumber),
        {
            refetchOnWindowFocus: false,
            staleTime: 300000 //5 minutes
        });

    const mutation = useMutation({
        mutationFn: (props: MutateProps) => {
            const {notificationToUpdate, isEmailEnabled, isSmsEnabled} = props;
            return new Client().defaultNotification_UpdateNotificationForCompressorAndUser(frameSerialNumber, {
                ...notificationToUpdate,
                isEmailEnabled,
                isSmsEnabled
            });
        },
        onMutate: async (props: MutateProps) => {
            const {notificationToUpdate, isEmailEnabled, isSmsEnabled} = props;
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey});

            // Snapshot the previous value
            const previousNotifications = queryClient.getQueryData<DefaultNotificationDTO[]>(queryKey);

            // Optimistically update to the new value
            if (previousNotifications) {
                queryClient.setQueryData<DefaultNotificationDTO[]>(queryKey, previousNotifications.map(x => {
                    return x.id === notificationToUpdate.id ? {
                        ...x,
                        isEmailEnabled,
                        isSmsEnabled
                    } : x;
                }));
            }
            return {previousNotifications};
        },
        onSuccess: (data, props: MutateProps) => {
            setOpen(true);
            setMessage(`Update for ${props.notificationToUpdate.description} was successful`);
            setSeverity("success");
        },
        onError: (err, props: MutateProps, context) => {
            setOpen(true);
            setMessage(`Update for ${props.notificationToUpdate.description} failed. Please try again.`);
            setSeverity("error");
            // If the mutation fails,
            // use the context returned from onMutate to roll back
            if (context?.previousNotifications) {
                queryClient.setQueryData<DefaultNotificationDTO[]>(queryKey, context.previousNotifications);
            }
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({queryKey});
        },
    });

    const resetAlertState = () => {
        setOpen(false);
        setMessage("");
        setSeverity("success");
    }

    return [query, mutation, {open, message, severity, resetAlertState}]
}