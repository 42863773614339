import React from 'react';
import Check from "../../../../images/Check.svg";
import Caution from "../../../../images/Caution.svg";
import './valveStatusIcon.scss'
import {ValveLeakSeverity} from "../../../../enums";

export interface ValveStatusIconProps {
    leakSeverity: ValveLeakSeverity
}

export function ValveStatusIcon(props: ValveStatusIconProps) {
    const {leakSeverity} = props;

    switch (leakSeverity) {
        case ValveLeakSeverity.Unknown :
            return <div title="Unknown" className="valve-status-icon valve-status-disconnected"/>
        case ValveLeakSeverity.Critical :
            return <img src={Caution} title="Critical Leak (> 1.0)"
                        className="valve-status-icon valve-status-critical" alt='Critical leak'/>
        case ValveLeakSeverity.Moderate :
            return <img src={Caution} title="Moderate Leak (0.5-1.0)"
                        className="valve-status-icon valve-status-moderate" alt="Moderate leak"/>
        default:
            return <img src={Check} title="Normal (< 0.5)" className="valve-status-icon valve-status-normal"
                        alt="Normal"/>
    }
}