import {useCompressorBasicInformation} from "../../hooks/Compressor/useCompressorBasicInformation";
import './CompressorInformation.scss';
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {StatusIcon} from "../StatusIcon/StatusIcon";
import {useCompressorOperationalStatusCode} from "../../hooks/FleetDashboard/useCompressors";
import {useGetEndUserForCompressor} from "../../hooks/Compressor/useGetEndUserForCompressor";
import {convertDateStringToLocalTime} from "../../utilities/dateHelper";

interface CompressorInformationProps {
    frameSerialNumber: string
}

export function CompressorInformation(props: CompressorInformationProps) {
    const {frameSerialNumber} = props;
    const basicInfo = useCompressorBasicInformation(frameSerialNumber);
    const statusCode = useCompressorOperationalStatusCode(frameSerialNumber);
    const endUser = useGetEndUserForCompressor(frameSerialNumber);
    const {state} = useLocation();
    const [statusCodeValue, setStatusCodeValue] = useState(state?.statusCode);
    const [statusCodeLoading, setStatusCodeLoading] = useState(state?.statusCode == null);
    const [equipmentOperator, setEquipmentOperator] = useState<string|undefined>("");
    useEffect(() => {
        if (!statusCode.isLoading) {
            setStatusCodeValue(statusCode.data);
            setStatusCodeLoading(false);
        }
    }, [statusCode.isLoading, statusCode.data])

    useEffect(() => {
        if(endUser.data !== null && endUser.data !== basicInfo.data?.endUser ) {
            setEquipmentOperator(endUser.data);
        } else {
            setEquipmentOperator(basicInfo.data?.endUser);
        }
    }, [endUser, basicInfo]);


    const cleanUpLocationDisplay = () => {
        if (basicInfo.data?.location) {
            const location = basicInfo.data?.location.split(',');
            let lat = Number(location[0]).toFixed(2);
            let long = Number(location[1]).toFixed(2);
            return `${lat},${long}`;
        }
        return "";
    }

    return (
        <header className='compressor-information-container'>
            <div className='primary-information'>
                <div className='status-icon-container'>
                    <StatusIcon statusCode={statusCodeValue} isLoading={statusCodeLoading}/>
                </div>
                <div className='compressor-information-category-container'>
                    <span
                        className='compressor-nickname'>{basicInfo.data?.endUserUnitID ? basicInfo.data.endUserUnitID : frameSerialNumber}</span>
                    <span className='compressor-frameId'>{frameSerialNumber} - {basicInfo.data?.model}</span>
                    <span className='compressor-distributor'>{equipmentOperator}</span>
                </div>
            </div>
            <div className='compressor-information-category-container'>
                <div>
                    Location:
                    <a href={"https://www.google.com/maps/search/?api=1&query=" + basicInfo.data?.location}
                       target="_blank">{cleanUpLocationDisplay()}</a>
                </div>
                <div>
                    M-Link:
                    <span>{basicInfo.data?.mLinkSerial}</span>
                </div>
                <div>
                    Last updated:
                    <span>{basicInfo.data?.lastUpdated ? convertDateStringToLocalTime(basicInfo.data.lastUpdated): ''}</span>
                </div>
            </div>
        </header>
    )
}
