import React from 'react';
import {usePredictedValueRanges} from "../../hooks/Compressor/usePredictedValueRanges";
import {
    AnnotationDirective,
    Annotations,
    AnnotationsDirective,
    AxesDirective,
    AxisDirective,
    CircularGaugeComponent,
    Gradient,
    PointerDirective,
    PointersDirective,
    RangeDirective,
    RangesDirective
} from "@syncfusion/ej2-react-circulargauge";
import {Inject} from "@syncfusion/ej2-react-grids";
import './temperatureGauge.css';

export interface TemperatureGaugeProps {
    value: number,
    expectedValue: number
}

export function TemperatureGauge(props: TemperatureGaugeProps) {
    const {value, expectedValue} = props;
    const ranges = usePredictedValueRanges(expectedValue);
    const rangeWidth = 6;
    return (
        <CircularGaugeComponent width="200" height="200"
        >
            <Inject services={[Annotations, Gradient]}/>
            <AxesDirective>
                <AxisDirective minimum={ranges.error?.min} maximum={ranges.error?.max}
                               startAngle={270} endAngle={90}
                               majorTicks={{
                                   width: 0,
                                   height: 0,
                                   offset: 0,
                                   interval: (ranges.error?.max - ranges.error?.min) / 2
                               }}
                               minorTicks={{interval: 0}}
                               labelStyle={{offset: -10, font: {size: "11px"}, format: 'n0', position: "Outside"}}>
                    <PointersDirective>
                        <PointerDirective radius="90%" markerWidth={13} markerHeight={13} type="Marker"
                                          markerShape="Triangle" value={value} color="#303030"
                                          cap={{radius: 35, color: "#fff", border: {color: '#ccc', width: 1}}}
                                          animation={{enable: false, duration: 0}}
                        />
                        <PointerDirective pointerWidth={0}
                                          cap={{radius: 47, color: "#fff", border: {color: '#ccc', width: 1}}}
                                          animation={{enable: false, duration: 0}}
                        />
                    </PointersDirective>
                    <AnnotationsDirective>
                        <AnnotationDirective
                            content={`<div class="temperature-gauge-value-container"><div class="temperature-gauge-value">${value}</div><div class="temperature-gauge-units">°F</div></div>`}
                            angle={180} radius='0%' zIndex='1'/>
                        <AnnotationDirective content={`<div class="temperature-gauge-subtitle">Temperature</div>`}
                                             angle={170} radius='80%' zIndex='1'/>
                    </AnnotationsDirective>
                    <RangesDirective>
                        <RangeDirective start={ranges.error?.min} end={ranges.warning?.min} color="#F03E3E"
                                        radius='100%' startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '0%', y: '100%'},
                            outerPosition: {x: '0%', y: '100%'},
                            colorStop: [
                                {color: 'blue', offset: '0%', opacity: 1},
                                {color: 'lightBlue', offset: '150%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.warning?.min} end={ranges.ok?.min} color="#FFDD00" radius='100%'
                                        startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '0%', y: '100%'},
                            outerPosition: {x: '0%', y: '100%'},
                            colorStop: [
                                {color: 'lightBlue', offset: '70%', opacity: 1},
                                {color: '#30B32D', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.ok?.min} end={ranges.ok?.max} color="#30B32D" radius='100%'
                                        startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '50%', y: '0%'},
                            outerPosition: {x: '50%', y: '0%'},
                            colorStop: [
                                {color: '#30B32D', offset: '40%', opacity: 1},
                                {color: '#FFDD00', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.ok?.max} end={ranges.warning?.max} color="#FFDD00" radius='100%'
                                        startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '0%', y: '100%'},
                            outerPosition: {x: '100%', y: '100%'},
                            colorStop: [
                                {color: '#FFDD00', offset: '50%', opacity: 1},
                                {color: '#30B32D', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.warning?.max} end={ranges.error?.max} color="#F03E3E"
                                        radius='100%' startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '100%', innerPosition: {x: '100%', y: '70%'},
                            outerPosition: {x: '100%', y: '100%'},
                            colorStop: [
                                {color: '#F03E3E', offset: '20%', opacity: 1},
                                {color: '#FFDD00', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                    </RangesDirective>
                </AxisDirective>
            </AxesDirective>
        </CircularGaugeComponent>
    );
}