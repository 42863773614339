import React, {useRef} from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    GridComponent,
    Inject,
    Reorder,
    Resize,
    Sort,
    TextWrapSettingsModel,
    Toolbar,
    ToolbarItems
} from "@syncfusion/ej2-react-grids";
import {useSoftwareVersionFleetReport} from "../../../hooks/Reports/useSoftwareVersionFleetReport";
import {GridSkeleton} from "../../../components/Skeletons/GridSkeleton";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import './softwareVersionFleetReport.scss'
import {ApiException} from "../../../autogenerate/api.generated.clients";
import UnauthorizedAlert from "../../../components/UnauthorizedAlert/UnauthorizedAlert";

function SoftwareVersionFleetReport() {
    const {data, isLoading, isError, error} = useSoftwareVersionFleetReport();
    const grid = useRef<GridComponent>(null);
    const toolbarOptions: ToolbarItems[] = ['Search', 'ExcelExport'];
    const wrapSettings: TextWrapSettingsModel = {wrapMode: 'Header'};
    const toolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.excelExport({fileName: 'ASC_SoftwareVersionFleetReport.xlsx'});
        }
    };
    const created = () => {
        document?.getElementById(grid.current?.element.id + "_searchbar")?.addEventListener('keyup', (event) => {
            grid.current?.search((event.target as HTMLInputElement).value)
        });
    };
    if (isLoading) {
        return (
            <GridSkeleton columns={13} rows={13}/>
        )
    }
    if (isError && error instanceof ApiException && error.status == 403) {
        return (<UnauthorizedAlert/>);
    }
    return (
        <div>
            <GridComponent allowTextWrap textWrapSettings={wrapSettings} created={created} allowReordering allowResizing
                           toolbarClick={toolbarClick} ref={grid}
                           id="software-version-fleet-report" allowMultiSorting allowSorting dataSource={data}
                           toolbar={toolbarOptions} allowExcelExport enableStickyHeader>
                <ColumnsDirective>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='compressorSN' headerText='Compressor SN'/>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='endUserUnitID'
                                     headerText='End User Unit ID'/>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='provisioningDate'
                                     headerText='Provisioning Date'/>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='activeCarrier' headerText='Active Carrier'/>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='motFileRev' headerText='MOT File Rev'/>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='mLinkSerial' headerText='M-Link Serial'/>
                    <ColumnDirective clipMode="EllipsisWithTooltip" field='firmwareVersion'
                                     headerText='Firmware Version'/>
                </ColumnsDirective>
                <Inject services={[Toolbar, Sort, ExcelExport, Resize, Reorder]}/>
            </GridComponent>
        </div>
    );
}

export default SoftwareVersionFleetReport;