import {useQuery} from "react-query";
import {Client} from "../../../autogenerate/api.generated.clients";

export interface UseCompressorDiagramProps {
    frameSerialNumber: string
}

export function useCompressorDiagram(props: UseCompressorDiagramProps) {
    const {frameSerialNumber} = props;
    return useQuery(["compressor-diagram", frameSerialNumber], () => new Client().compressor_GetConfigDiagram(frameSerialNumber))
}