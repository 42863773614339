import {ChartingVariable, Client} from "../../../autogenerate/api.generated.clients";
import {useQuery} from "react-query";

export interface UseLubeOilChartDataProps {
    frameSerialNumber: string,
    startDate: Date,
    endDate: Date
}

export interface UseLubeOilChartDataResponse {
    rpm: ChartingVariable | undefined,
    forceFeedEven: ChartingVariable | undefined,
    forceFeedOdd: ChartingVariable | undefined
}

export const useLubeOilChartData = (props: UseLubeOilChartDataProps) => {
    return useQuery(["lube-oil-chart-data", props.frameSerialNumber, props.startDate, props.endDate], () => new Client().chart_GetLubeOilChartData({
        frameSerialNumber: props.frameSerialNumber,
        startDate: props.startDate.toUTCString(),
        endDate: props.endDate.toUTCString(),
        variables: []
    }));
}