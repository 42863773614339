import {useEffect, useState} from "react";

export interface MinMaxRange {
    min: number,
    max: number
}

export interface PredictedValueRanges {
    ok: MinMaxRange,
    warning: MinMaxRange,
    error: MinMaxRange
}

export const usePredictedValueRanges = function (predictedValue: number): PredictedValueRanges {
    const [predictedRanges, setPredictedRanges] = useState<PredictedValueRanges>({} as PredictedValueRanges);

    useEffect(() => {
        setPredictedRanges({
            ok: {
                min: Math.max(predictedValue - (predictedValue * .1), 0),
                max: Math.max(predictedValue + (predictedValue * .1), 0)
            },
            warning: {
                min: Math.max(predictedValue - (predictedValue * .2), 0),
                max: Math.max(predictedValue + (predictedValue * .2), 0)
            },
            error: {
                min: Math.max(predictedValue - (predictedValue * .3), 0),
                max: Math.max(predictedValue + (predictedValue * .3), 0)
            }
        });
    }, [predictedValue]);

    return predictedRanges;
}