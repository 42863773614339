import {useQuery} from "react-query";
import {Client} from "../../../autogenerate/api.generated.clients";

export interface UseCylinderTelemetryPropertiesProps {
    cylinderSN: string,
    isPositionHE: boolean,
    frameSerialNumber: string
}

export function useCylinderTelemetryProperties(props: UseCylinderTelemetryPropertiesProps) {
    const {cylinderSN, frameSerialNumber, isPositionHE} = props;
    return useQuery(["cylinder-telemetry", cylinderSN], () => new Client().cylinder_GetTelemetryProperties(cylinderSN, frameSerialNumber, isPositionHE))
}