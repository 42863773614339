import {useQuery} from "react-query";
import {Client, CompressorAlert} from "../../autogenerate/api.generated.clients";
import {AlertResolutionType} from "../../enums";

export interface BaseCompressorAlertsProps {
    frameId: string | null
}

export interface DateEnabledCompressorAlertsProps extends BaseCompressorAlertsProps {
    alertResolutionStartDate?: Date | undefined,
    alertResolutionEndDate?: Date | undefined
}

export function useActiveCompressorAlerts(props: BaseCompressorAlertsProps, enabled: boolean = true) {
    return useQuery<CompressorAlert[], Error>(["active-compressor-alerts", props.frameId], () => new Client().alert_GetCompressorAlertsByResolutionStatus(props.frameId!, AlertResolutionType.Active), {
        enabled, refetchOnWindowFocus:false, staleTime: 300000
    });
}

export function useResolvedCompressorAlerts(props: DateEnabledCompressorAlertsProps, enabled: boolean = true) {
    return useQuery<CompressorAlert[], Error>(["resolved-compressor-alerts", props], () => new Client().alert_GetCompressorAlertsByResolutionStatus(props.frameId!, AlertResolutionType.Resolved, props.alertResolutionStartDate?.toUTCString(), props.alertResolutionEndDate?.toUTCString()), {
        enabled, refetchOnWindowFocus:false
    });
}