import {useQuery} from "react-query";
import {Client, Compressor, CompressorStatusSummary} from "../../autogenerate/api.generated.clients";

export function useCompressors() {
    return useQuery<Compressor[], Error>("compressors", () => new Client().compressor_GetAll(false), {staleTime: 300000});
}

export const useCompressorOperationalStatusCode = function (frameSerialNumber: string) {
    return useQuery<number, Error>(["compressors-operationalStatusCode", frameSerialNumber], () => new Client().compressor_GetCompressorOperationalStatusCode(frameSerialNumber));
}

export const useCompressorStatusSummary = function (frameSerialNumber: string) {
    return useQuery<CompressorStatusSummary[], Error>(["compressors-statusSummary", frameSerialNumber], () => new Client().compressor_GetCompressorStatusSummary(frameSerialNumber));
}

export const useUpdateEndUserUnitId = function () {
    return {
        updateEndUserUnitId: (frameId: string, endUserUnitId: string) => new Client().compressor_UpdateCompressorEndUserUnitId(frameId, endUserUnitId)
    }
}

