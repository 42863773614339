import {Skeleton} from "@mui/material";
import './skeletons.scss'

function ListItemSkeleton() {
    return (
        <div className='list-item-skeleton'>
            <Skeleton variant='circular' width={35} height={35}/>
            <div className='list-item-text'>
                <Skeleton variant='text' width='50%'/>
                <Skeleton variant='text' sx={{fontSize: '.75rem'}} width='25%'/>
            </div>
        </div>
    );
}

export default ListItemSkeleton;