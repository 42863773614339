import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useCompressors} from "../../hooks/FleetDashboard/useCompressors";
import {Compressor} from "../../autogenerate/api.generated.clients";
import {useCreateCompressorOrgAssociation} from "../../hooks/Organization/useCreateCompressorOrgAssociation";

export interface CompressorManagerDialogProps {
    orgId: string | undefined,
    orgAssociatedCompressorSns: string[] | null,
    isDialogOpen: boolean,
    toggleDialogState: Function,
    handleFrameSubmit: Function
}
export function CompressorManagerDialog(props: CompressorManagerDialogProps){
    const {orgId, orgAssociatedCompressorSns, isDialogOpen, toggleDialogState, handleFrameSubmit} = props;
    const [searchText, setSearchText] = useState<string>("");
    const [availableCompressors, setAvailableCompressors] = useState<Compressor[] | null>()
    const [selectedIndex, setSelectedIndex] = useState<number| null>(null);
    const [framesToAdd, setFramesToAdd] = useState<string[]>([] as string[]);
    const compressorsForUser = useCompressors();
    const createCompressorOrgAssociation = useCreateCompressorOrgAssociation();
    
    useEffect(() => {
        if(compressorsForUser.data && orgAssociatedCompressorSns){
            setAvailableCompressors(compressorsForUser.data.filter(i => !orgAssociatedCompressorSns.includes(i.frameSerialNumber)));
        }
    }, [compressorsForUser.data, orgAssociatedCompressorSns])
    const handleDialogClose = () => {
        toggleDialogState();
        setFramesToAdd([] as string[]);
    }

    const handleFrameDialogFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }

    const handleFrameSelectionClick = async (compressor: Compressor, event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        let frames = framesToAdd.slice();
        frames.push(compressor.frameSerialNumber);
        setFramesToAdd(frames);
        setSelectedIndex(index);
    }

    const onDialogSubmit = async () => {
        if(framesToAdd.length > 0) {
            let frameSerialNumber = framesToAdd[framesToAdd.length - 1];
            const organizationId = orgId ? parseInt(orgId) : 0;
            let isCreateSuccess = await createCompressorOrgAssociation.createCompressorOrganizationAssociation(frameSerialNumber, organizationId);
            setFramesToAdd([] as string[]);
            handleFrameSubmit(isCreateSuccess, `Successfully added Frame(s) to ${orgId}`);
        }
        handleDialogClose();
    }
        
    return (
        <Dialog
            open={isDialogOpen}
            maxWidth="sm"
            fullWidth={true}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Add Frame
            </DialogTitle>
            <DialogContent>
                <>
                    <Box
                        component={"form"}
                        noValidate
                        autoComplete={"off"}
                    >
                        <TextField
                            id={"filter-compressor-list"}
                            label={"Filter Compressors"}
                            variant={"outlined"}
                            margin={"normal"}
                            fullWidth
                            value={searchText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {handleFrameDialogFilter(event)}}
                            InputProps={{
                                type: 'search',
                            }}
                        />
                    </Box>
                    <List>
                        { availableCompressors?.length ?
                            availableCompressors
                                .filter(c => !searchText || c.frameSerialNumber.toLowerCase().includes(searchText.toLowerCase()))
                                .map((compressor, index) => (
                                    <ListItem disableGutters key={compressor.frameSerialNumber} >
                                        <ListItemButton
                                            selected={selectedIndex === index}
                                            onClick={(event) => {handleFrameSelectionClick(compressor, event, index)}}
                                        >
                                            {compressor.frameSerialNumber}
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            :
                            "No frames to add"
                        }
                    </List>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogSubmit} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}