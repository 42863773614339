import {ChartingDataParams, Client} from "../../../../autogenerate/api.generated.clients";
import {useQuery} from "react-query";

export interface UseChartingDataProps {
    params: ChartingDataParams

}

export const useChartingData = (props: UseChartingDataProps) => {
    return useQuery(["charting-data", props.params], () => new Client().chart_GetChartData(props.params));
}
