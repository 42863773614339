import React, {useState} from 'react';
import {useLubeOilChartData} from "../../../../hooks/Compressor/LubeOil/useLubeOilChartData";
import {getDaysAgo, getHoursAgo, getOneDayAgo} from "../../../../utilities/dateHelper";
import {ChartSkeleton} from "../../../../components/Skeletons/ChartSkeleton";
import {DropDownListComponent, SelectEventArgs} from "@syncfusion/ej2-react-dropdowns";
import './compressorLubeOilChart.scss'
import {LineChartMultiYAxis} from "../../../../components/Charting/LineChart/LineChart";
import {ChartingVariable} from "../../../../autogenerate/api.generated.clients";
import {Card, Typography} from "@mui/material";

export interface CompressorLubeOilChartProps {
    frameSerialNumber: string
}

export function CompressorLubeOilChart(props: CompressorLubeOilChartProps) {
    const {frameSerialNumber} = props;
    const [startDate, setStartDate] = useState(getOneDayAgo());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedDuration, setSelectedDuration] = useState("24 Hours");
    const {data, isLoading, error} = useLubeOilChartData({frameSerialNumber, startDate, endDate});
    const durationOptions: string[] = ["1 Hour", "12 Hours", "24 Hours", "7 Days", "30 Days"];
    const onDurationSelected = (args: SelectEventArgs) => {
        let newValue = args.itemData.value;
        if (newValue != selectedDuration) {
            setSelectedDuration(args.itemData.value ?? "");
            switch (newValue) {
                case "1 Hour":
                    setStartDate(getHoursAgo(1));
                    setEndDate(new Date());
                    break;
                case "12 Hours":
                    setStartDate(getHoursAgo(12));
                    setEndDate(new Date());
                    break;
                case "24 Hours":
                    setStartDate(getOneDayAgo());
                    setEndDate(new Date());
                    break;
                case "7 Days":
                    setStartDate(getDaysAgo(7))
                    setEndDate(new Date());
                    break;
                case "30 Days":
                    setStartDate(getDaysAgo(30))
                    setEndDate(new Date());
                    break;
            }
        }
    }
    if (isLoading || !data) {
        return (
            <Card className="lube-oil-chart-container">
                <ChartSkeleton/>
            </Card>
        )
    }

    return (
        <div>
            <Card className="lube-oil-chart-container">
                <div className="lube-oil-chart-title">
                    <Typography fontSize="1.25em">Cycle times vs RPM</Typography>
                </div>
                <DropDownListComponent className="duration-dropdown" width="100px" select={onDurationSelected}
                                       dataSource={durationOptions} value={selectedDuration}/>
               <LineChartMultiYAxis leftAxisVariables={[data.forceFeedEvenCycleTime, data.forceFeedOddCycleTime] as ChartingVariable[]} rightAxisVariables={[data.compressorSpeed] as ChartingVariable[]} showDigitalTwin={false} leftAxisLabel={'Seconds'} rightAxisLabel={'RPM'} showMark={!selectedDuration.includes('Days')}/>
            </Card>
        </div>
    );
}