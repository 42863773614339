import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {AuthLoader} from "./loaders/AuthLoader";
import {QueryClient, QueryClientProvider} from "react-query";
import App from "./App";
import {ConfigProvider} from "./providers/ConfigProvider";
import {AuthProvider} from "./providers/AuthProvider";
import TermsAndConditionsLoader from "./loaders/TermsAndConditionsLoader.tsx/TermsAndConditionsLoader";
import AuthorizationChecker from "./loaders/AuthorizationChecker/AuthorizationChecker";
import FeatureFlagProvider from "./providers/FeatureFlagProvider/FeatureFlagProvider";
import ReactGA from 'react-ga4';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement as Element);

const queryClient = new QueryClient({defaultOptions: {queries: {retry: false}}});

ReactGA.initialize("G-L477SBC60N")

root.render(
    <ConfigProvider>
        <AuthProvider>
            <AuthLoader>
                <FeatureFlagProvider>
                    <QueryClientProvider client={queryClient}>
                        <AuthorizationChecker>
                            <TermsAndConditionsLoader>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                            </TermsAndConditionsLoader>
                        </AuthorizationChecker>
                    </QueryClientProvider>
                </FeatureFlagProvider>
            </AuthLoader>
        </AuthProvider>
    </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();