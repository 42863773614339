import './afmAppBar.css';
import {Link} from "react-router-dom";
import {useActiveFleetAlerts} from "../../hooks/FleetDashboard/useFleetAlerts";
import SubmitLink from "@mui/material/Link"
import {useGetIsArielEmployee} from "../../hooks/Organization/useGetIsArielEmployee";
import {SubmitFeedbackUrl} from "../../constants";
import {useIsActionableIntelEnabled} from "../../hooks/FeatureFlags/useIsActionableIntelEnabled";
import {AppBar, Button, Toolbar} from "@mui/material";

export function AfmAppBar() {
    const activeAlerts = useActiveFleetAlerts();
    const isArielEmployee = useGetIsArielEmployee();
    const isActionableIntelEnabled = useIsActionableIntelEnabled();
    return (
        <div className='control-container'>
            <AppBar id="main-appbar">
                <Toolbar className='appbar-toolbar' disableGutters>
                    
                <Button><Link to="/">Dashboard</Link></Button>
                <Button><Link to="/alerts"><span>Alerts</span>
                    <sup>{activeAlerts?.data?.length}</sup></Link></Button>
                {/*<ButtonComponent cssClass='e-inherit menu'><Link*/}
                {/*    to="/notifications">Notifications</Link></ButtonComponent>*/}
                {isActionableIntelEnabled ?
                    <Button><Link to="/troubleshooting">Fault Troubleshooting</Link></Button>
                    : ''
                }
                {isArielEmployee.data ?

                    <Button><Link to="/reports">Reports</Link></Button>
                    : ''
                }
                {isArielEmployee.data ?
                    <Button><Link to="/administration">Administration</Link></Button>
                    : ''
                }
                <Button>
                    <SubmitLink href={SubmitFeedbackUrl} target='_blank' rel="noopener">
                        Submit Feedback
                    </SubmitLink>
                </Button>
                </Toolbar>
            </AppBar> 
                
        </div>);
}