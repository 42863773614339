import React, {useState} from 'react';
import {getOneMonthAgo, minuteDifference} from "../../../../utilities/dateHelper";
import {useResolvedCompressorAlerts} from "../../../../hooks/Compressor/useCompressorAlerts";
import {ChangedEventArgs, DateTimePickerComponent} from "@syncfusion/ej2-react-calendars";
import {AlertsGrid} from "../../../../components/AlertsGrid/AlertsGrid";
import './compressorResolvedAlertsTab.css';
import {ButtonComponent} from "@syncfusion/ej2-react-buttons";

interface CompressorResolvedAlertsTabContentProps {
    frameId: string
}


export function CompressorResolvedAlertsTabContent(props: CompressorResolvedAlertsTabContentProps) {
    const [resolutionStartDate, setResolutionStartDate] = useState<Date>(getOneMonthAgo());
    const [resolutionEndDate, setResolutionEndDate] = useState<Date>(new Date());
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);
    const [dateLabelText, setDateLabelText] = useState("Get alerts resolved between ");
    const {data, isLoading, error} = useResolvedCompressorAlerts({
        frameId: props.frameId,
        alertResolutionStartDate: resolutionStartDate,
        alertResolutionEndDate: resolutionEndDate
    }, isQueryEnabled);

    const onResolutionStartChanged = (args: ChangedEventArgs) => {
        if (args.value && minuteDifference(args.value, resolutionStartDate) >= 30) {
            setResolutionStartDate(args.value)
        }
    }
    const onResolutionEndChanged = (args: ChangedEventArgs) => {
        if (args.value && minuteDifference(args.value, resolutionEndDate) >= 30) {
            setResolutionEndDate(args.value)
        }
    }
    const onGetCompressorAlerts = () => {
        setIsQueryEnabled(true);
        setDateLabelText("Showing alerts resolved between ");
    }

    return (
        <div>
            <div className="compressor-resolved-alerts-search-form-container">
                <span>{dateLabelText}</span>
                <DateTimePickerComponent width="200px" showClearButton={false} allowEdit={false} openOnFocus={true}
                                         value={resolutionStartDate} change={onResolutionStartChanged}/>
                <span> and </span>
                <DateTimePickerComponent width="200px" showClearButton={false} allowEdit={false} openOnFocus={true}
                                         value={resolutionEndDate} change={onResolutionEndChanged}/>
            </div>
            {!isQueryEnabled ?
                <ButtonComponent isPrimary cssClass="get-compressor-alerts-button" onClick={onGetCompressorAlerts}>Get
                    Alerts</ButtonComponent>
                : <AlertsGrid data={data} isLoading={isLoading}/>
            }
        </div>
    );
}


