import React from "react";
import {Alert, AlertColor, Snackbar} from "@mui/material";

export type AlertInfo = { open: boolean, message: string, severity: AlertColor, resetAlertState: () => void }

function AlertUser(props: AlertInfo) {
    const {open, severity, message, resetAlertState} = props;

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAlertState();
    };

    return <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                     anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
            {message}
        </Alert>
    </Snackbar>
}

export default AlertUser;