import React, {useState} from "react";
import {AlertsGrid} from "../AlertsGrid/AlertsGrid";
import {CompressorAlert} from "../../autogenerate/api.generated.clients";
import './alertSelectionPopup.scss'
import {Button, Typography} from "@mui/material";

export interface AlertSelectionPopupProps {
    alerts: CompressorAlert[] | undefined,
    onAlertsSelected: (selectedAlerts: CompressorAlert[])=>void,
    selectedAlerts: CompressorAlert[]
}

export default function AlertSelectionPopup(props: AlertSelectionPopupProps) {
    const {alerts, selectedAlerts} = props;
    const [selectedRows, setSelectedRows] = useState<CompressorAlert[]>(selectedAlerts);

    const onRowSelected = (selectedRecord: CompressorAlert) =>{
        setSelectedRows([...selectedRows, selectedRecord]);
    }

    const onRowDeselected = (selectedRecord: CompressorAlert) =>{
        setSelectedRows(selectedRows.filter(x=> JSON.stringify(selectedRecord) !== JSON.stringify(x)));
    }
    
    const onConfirmClicked = ()=>{
        props.onAlertsSelected(selectedRows);
    }
    
    return (
        <div className={'alert-selection-container'}>
            <Typography>Select alerts below and click confirm selection:</Typography>
            <div className={'alerts-grid-container'}>
                <AlertsGrid selectedAlerts={selectedRows} hiddenColumns={['source','endUserUnitId','frameSerialNumber','troubleshootingLink']} allowMultiSelect onRowSelected={onRowSelected} onRowDeselected={onRowDeselected} data={alerts} isLoading={alerts === undefined}/>
            </div>
            <div className='buttons-container'>
                <Button variant={'outlined'} className={'confirm-button'} onClick={()=>setSelectedRows([])}>Clear Selection</Button>
                <Button variant={'contained'} className={'confirm-button'} onClick={onConfirmClicked}>Confirm Selection</Button>
            </div>
        </div>
    )
}


