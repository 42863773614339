import React, {useEffect} from 'react';
import {PressureGauge} from "../../../components/PressureGauge/PressureGauge";
import './compressorConditionsSummary.scss';
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import {TemperatureGauge} from "../../../components/TemperatureGauge/TemperatureGauge";
import {DashboardCard} from "../../../components/DashboardCard/DashboardCard";
import {useConditions} from "../../../hooks/Compressor/useConditions";
import ValueNotReceivedMessage from "../../../components/ValueNotReceivedMessage/ValueNotReceivedMessage";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";

export default function CompressorConditionsSummary() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber;
    const conditions = useConditions(frameSerialNumber!);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/compressors/${frameSerialNumber}/conditions`, title: "Compressor Conditions Summary" });
    }, []);
    
    if (conditions.isLoading) {
        return (
            <CompressorConditionsLoadSkeleton/>
        )
    }

    return (
        <div className="conditions-summary-container">
            <div className="operation-statistics-container">
                <DashboardCard key='driver-speed' label="Driver speed"
                               value={`${conditions.data?.driverSpeedRPM} RPM`} data-testid="driver-speed-card"/>
                <DashboardCard key='flow' label="Flow" value={`${conditions.data?.flowMMSCFD?.toFixed(3)} MMSCFD`}/>
                <DashboardCard key='oil-temperature' label="Oil temperature"
                               value={`${conditions.data?.oilTemperatureF} °F`}/>
                <DashboardCard key='oil-post-filter-temperature' label="Oil post-filter pressure"
                               value={`${conditions.data?.oilPostFilterPressurePSI} psi`}/>
                <DashboardCard key='driver-hours' label="Driver hours" value={`${conditions.data?.driverHours}`}/>
            </div>
            <div className="stages-container">
                {
                    conditions.data?.stages.map((stage, index) => {
                        return (
                            <div className="conditions-stage e-card" key={`stage-pressures-${index + 1}`}>
                                <div className="e-card-header">
                                    <div className="e-card-header-caption">
                                        <div className="e-card-title">Stage {stage.stageNumber}</div>
                                    </div>
                                </div>
                                <div style={{display: "flex"}}>
                                    {stage.suctionPressurePsi !== null && stage.predictedSuctionPressurePsi !== null
                                        ? <PressureGauge title="Suction" value={stage.suctionPressurePsi!}
                                                         expectedValue={stage.predictedSuctionPressurePsi!} data-testid="suction-pressure-gauge"/>
                                        : <div className='value-not-received-container'><ValueNotReceivedMessage/></div>
                                    }
                                    {stage.dischargePressurePsi !== null && stage.predictedDischargePressurePsi !== null
                                        ? <PressureGauge title="Discharge"
                                                         value={stage.dischargePressurePsi!}
                                                         expectedValue={stage.predictedDischargePressurePsi!}/>
                                        : <div className='value-not-received-container'><ValueNotReceivedMessage/></div>
                                    }

                                </div>
                                <div className="temperature-gauge-row">
                                    {stage.suctionTemperatureF !== null && stage.predictedSuctionTemperatureF !== null
                                        ? <TemperatureGauge value={stage.suctionTemperatureF!}
                                                            expectedValue={stage.predictedSuctionTemperatureF!}/>
                                        : <div className='value-not-received-container'><ValueNotReceivedMessage/></div>
                                    }
                                    {stage.dischargeTemperatureF !== null && stage.predictedSuctionTemperatureF !== null
                                        ? <TemperatureGauge value={stage.dischargeTemperatureF!}
                                                            expectedValue={stage.predictedDischargeTemperatureF!} data-testid="discharge-temperature-gauge"/>
                                        : <div className='value-not-received-container'><ValueNotReceivedMessage/></div>
                                    }
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    );
}

function CompressorConditionsLoadSkeleton() {
    return (
        <div className="conditions-summary-container">
            <div className="operation-statistics-container">
                <DashboardCard key='driver-speed' label="Driver speed"
                               value={'RPM'} isLoading={true}/>
                <DashboardCard key='flow' label="Flow" value={`MMSCFD`} isLoading={true}/>
                <DashboardCard key='oil-temperature' label="Oil temperature"
                               value={`°F`} isLoading={true}/>
                <DashboardCard key='oil-post-filter-temperature' label="Oil post-filter pressure"
                               value={`psi`} isLoading={true}/>
                <DashboardCard key='driver-hours' label="Driver hours" value={``} isLoading={true}/>
            </div>
            <div className="stages-container">
                <div className="conditions-stage e-card">
                    <div className="e-card-header">
                        <SkeletonComponent height={25} width={100}/>
                    </div>
                    <div style={{display: "flex", marginBottom: '1em', gap: '1em'}}>
                        <SkeletonComponent shape="circle" width="165" height="165"/>
                        <SkeletonComponent shape="circle" width="165" height="165"/>
                    </div>
                    <div style={{display: "flex", marginBottom: '1em', gap: '1em'}}>
                        <SkeletonComponent shape="circle" width="145" height="145"/>
                        <SkeletonComponent shape="circle" width="145" height="145"/>
                    </div>
                </div>
                <div className="conditions-stage e-card">
                    <div className="e-card-header">
                        <SkeletonComponent height={25} width={100}/>
                    </div>
                    <div style={{display: "flex", marginBottom: '1em', gap: '1em'}}>
                        <SkeletonComponent shape="circle" width="165" height="165"/>
                        <SkeletonComponent shape="circle" width="165" height="165"/>
                    </div>
                    <div style={{display: "flex", marginBottom: '1em', gap: '1em'}}>
                        <SkeletonComponent shape="circle" width="145" height="145"/>
                        <SkeletonComponent shape="circle" width="145" height="145"/>
                    </div>
                </div>
            </div>
        </div>
    )
}