import FaultList from "../../components/FaultRepository/FaultList/FaultList";
import {useFaultRepositoryInfo} from "../../hooks/Compressor/useFaultRepositoryInfo";
import {Alert, Button} from "@mui/material";
import ListItemSkeleton from "../../components/Skeletons/ListItemSkeleton";
import useQueryParams from "../../hooks/useQueryParams";
import {useEffect} from "react";
import ReactGA from "react-ga4";
import {SubmitFeedbackUrl} from "../../constants";
import "./faultTroubleShootingRepository.scss";

export default function FaultTroubleShootingRepository() {
    const queryParams = useQueryParams();
    const {data, isLoading} = useFaultRepositoryInfo();
    const faultId = queryParams.get("faultId");

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/troubleshooting", title: "Fault Troubleshooting" });
    }, []);
    
    let initialFaultId;
    
    if(isLoading || !data) {
        return(<ListItemSkeleton/>)
    }
    
    if(faultId){
        initialFaultId = faultId
    }else if(data) {
        initialFaultId = data[0].faults[0].ascFaultId.toString()
    }else {
        initialFaultId = '0'
    }
    return (
        <div className="fault-troubleshooting-container">
            <a href={SubmitFeedbackUrl} style={{textDecoration: 'none'}} target='_blank'>
                <Alert
                    sx={{margin: '1em 1em -.5em 1em'}}
                    severity="info"
                    action={
                        <Button color="inherit" size="small" target='_blank' href={SubmitFeedbackUrl}>
                            Submit Feedback
                        </Button>
                    }
                >
                How can we make this more useful for you?
                </Alert>
            </a>
            <FaultList initialFaultId={initialFaultId} data={data}/>
        </div>
)
}