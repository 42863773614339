import {FaultCategory} from "../autogenerate/api.generated.clients";

export function GetFaultCategoryDisplayValue(faultCategory: FaultCategory) {
    switch (faultCategory) {
        case FaultCategory.Bearings:
            return "Bearings";

        case FaultCategory.ArielFleetManager:
            return "AFM";

        case FaultCategory.LubeOil:
            return "Lube Oil";

        case FaultCategory.Valves:
            return "Valves";

        case FaultCategory.ControllerSkid:
            return "Controller/Skid";

        case FaultCategory.Instrumentation:
            return "Instrumentation";

        case FaultCategory.OperatingConditions:
            return "Operating Conditions";

        case FaultCategory.RodLoadReversal:
            return "Rod Load/Reversal";

        case FaultCategory.Vibration:
            return "Vibration";

        default:
            return "Other";
    }
}