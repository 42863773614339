import React, {useEffect, useState} from 'react';
import {AlertsGrid} from "../../components/AlertsGrid/AlertsGrid";
import {
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    BreadcrumbItemsDirective,
    TabAnimationSettingsModel,
    TabComponent,
    TabItemDirective,
    TabItemsDirective
} from "@syncfusion/ej2-react-navigations";
import {useActiveFleetAlerts} from "../../hooks/FleetDashboard/useFleetAlerts";
import {getOneDayAgo} from "../../utilities/dateHelper";
import {FleetResolvedAlertsTabContent} from "./FleetResolvedAlertsTab/FleetResolvedAlertsTabContent";
import './fleetAlertsPage.css';
import {GridSkeleton} from "../../components/Skeletons/GridSkeleton";
import ReactGA from "react-ga4";

export function FleetAlertsPage() {
    const [resolutionStartDate, setResolutionStartDate] = useState<Date>(getOneDayAgo());
    const [resolutionEndDate, setResolutionEndDate] = useState<Date>(new Date());
    const activeAlerts = useActiveFleetAlerts();
    
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/alerts", title: "Fleet Alerts" });
    }, []);
    
    
    if (activeAlerts.isLoading) {
        return (<GridSkeleton columns={6} rows={6}/>);
    }


    const activeAlertsTab = () => <AlertsGrid data={activeAlerts?.data} isLoading={activeAlerts.isLoading}
                                              hiddenColumns={["resolutionTimeStamp"]}/>
    const tabAnimation: TabAnimationSettingsModel = {
        previous: {effect: 'None'},
        next: {effect: 'None'}
    };
    return (
        <div className="fleet-alerts-page-container">
            <BreadcrumbComponent enableNavigation>
                <BreadcrumbItemsDirective>
                    <BreadcrumbItemDirective text="My Fleet" url="/"/>
                    <BreadcrumbItemDirective text="Alerts" url="./breadcrumb/default"/>
                </BreadcrumbItemsDirective>
            </BreadcrumbComponent>
            <TabComponent heightAdjustMode="Auto" animation={tabAnimation} cssClass="fleet-alerts-tabs">
                <TabItemsDirective>
                    <TabItemDirective headerText="Active Alerts" content={activeAlertsTab}/>
                    <TabItemDirective headerText="Resolved Alerts" content={FleetResolvedAlertsTabContent}/>
                </TabItemsDirective>
            </TabComponent>
        </div>
    );
}

