import React from 'react';
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import './skeletons.scss'

export function ChartSkeleton() {
    return (
        <div className="chart-skeleton-container">
            <div className="chart-skeleton-left">
                <SkeletonComponent shape="text" width='50%' height="10"/>
                <SkeletonComponent shape="text" width='50%' height="10"/>
                <SkeletonComponent shape="text" width='50%' height="10"/>
                <SkeletonComponent shape="text" width='50%' height="10"/>
                <SkeletonComponent shape="text" width='50%' height="10"/>
            </div>
            <div className="chart-skeleton-right">
                <SkeletonComponent shape="Rectangle" width="100%" height="90%"/>
                <div className="chart-skeleton-x-labels">
                    <SkeletonComponent shape="Text" width="50px" height="12px" cssClass="chart-skeleton-x-axis-label"/>
                    <SkeletonComponent shape="Text" width="50px" height="12px" cssClass="chart-skeleton-x-axis-label"/>
                    <SkeletonComponent shape="Text" width="50px" height="12px" cssClass="chart-skeleton-x-axis-label"/>
                    <SkeletonComponent shape="Text" width="50px" height="12px" cssClass="chart-skeleton-x-axis-label"/>
                    <SkeletonComponent shape="Text" width="50px" height="12px" cssClass="chart-skeleton-x-axis-label"/>
                    <SkeletonComponent shape="Text" width="50px" height="12px" cssClass="chart-skeleton-x-axis-label"/>

                </div>
            </div>
        </div>
    );
}