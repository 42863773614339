import React from 'react';
import {CompressorStatusCode} from "../../autogenerate/api.generated.clients";
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import './statusIcon.scss'
import ShutdownFault from '../../images/ShutdownFault.svg';
import Check from '../../images/Check.svg'
import Caution from '../../images/Caution.svg'
import RunningShutdownFault from '../../images/RunningShutdownFault.svg'

export interface StatusIconProps {
    statusCode: CompressorStatusCode | undefined,
    isLoading?: boolean,

}

export function StatusIcon(props: StatusIconProps) {
    const {statusCode, isLoading} = props;

    if (isLoading) {
        return <SkeletonComponent data-testid="skeleton" shape="Circle" width="100%" height="100%"/>
    }
    switch (statusCode) {
        case CompressorStatusCode.OK :
            return <img src={Check} title="OK" className="status-icon-base status-icon-ok" alt="Status Icon Ok"/>
        case CompressorStatusCode.ActiveWarning :
            return <img src={Caution} title="Active Warning" className="status-icon-base status-icon-active-warning"
                        alt="Status Icon Active Warning"/>
        case CompressorStatusCode.NormalShutdown :
            return <div title="Normal Shutdown" className="status-icon-base status-icon-normal-shutdown"/>
        case CompressorStatusCode.ShutdownFault :
            return <img src={ShutdownFault} title="Shutdown Fault"
                        className="status-icon-base status-icon-shutdown-fault" alt='Status Icon Shutdown Fault'/>
        case CompressorStatusCode.RunningShutdownFault :
            return <img src={RunningShutdownFault} title="Running Shutdown Fault"
                        className="status-icon-base status-icon-running-shutdown" alt='Status Icon Running Shutdown Fault'/>
        case CompressorStatusCode.NonASC :
            return <div title="Non-ASC" className="status-icon-base status-icon-non-asc"/>
        case CompressorStatusCode.Disconnected :
            return <div title="Disconnected" className="status-icon-base status-icon-disconnected"/>
    }
    return (
        <div title="Unknown" className="status-icon-base status-icon-non-asc"/>
    );
}