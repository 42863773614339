import React from 'react';
import './DashboardCard.scss';
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import {ContextMenuComponent, MenuItemModel} from "@syncfusion/ej2-react-navigations";

export interface DashboardCardProps {
    labelValuePairs: LabelValuePair[],
    title: string,
    isLoading?: boolean | undefined
    className?: string,
    contextMenuOptions?: MenuItemModel[],
    onContextMenuSelected?: Function | undefined
}

export interface LabelValuePair {
    label: string,
    value: string | number,
}

export function MultiValueDashboardCard(props: DashboardCardProps) {
    const {labelValuePairs, title, isLoading, className, contextMenuOptions, onContextMenuSelected} = props;
    let cMenu: ContextMenuComponent | null;

    const onContextMenuClick: React.MouseEventHandler = (e) => {
        cMenu?.open(e.clientY, e.clientX);
    }
    return (
        <div className={`e-card multi-value-dashboard-card ${className}`}>
            <div className="title-container">
                <span>{title}</span>
                {
                    contextMenuOptions ?
                        <div>
                            <button title="More actions" onClick={onContextMenuClick} id="dashboard-card-button"
                                    className="e-icons e-medium e-more-vertical-1"/>
                            <ContextMenuComponent ref={(scope) => cMenu = scope} target='#dashboard-card-button'
                                                  items={contextMenuOptions} showItemOnClick={true}
                                                  select={onContextMenuSelected as any}/>
                        </div>
                        : ""
                }
            </div>
            <div className="multi-value-container">
                {labelValuePairs.map(labelValuePair => {
                    const label = labelValuePair.label;
                    const value = labelValuePair.value;
                    return <div className="value-prop-container e-card-content" key={`${label}`}>
                        {
                            isLoading ? <SkeletonComponent style={{height: '2em', width: '60%'}}/>
                                : <span className="value-prop-value">{value}</span>
                        }
                        <span className="value-prop-label">{label}</span>
                    </div>
                })}
            </div>
        </div>
    );
}