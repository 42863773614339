import React, {useRef} from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    GridComponent,
    Inject,
    Sort,
    Toolbar,
    ToolbarItems
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import {GridSkeleton} from "../../../../components/Skeletons/GridSkeleton";
import {useCompressorThrowStageMapping} from "../../../../hooks/Compressor/Telemetry/useCompressorThrowStageMapping";

export interface ThrowStageMappingProps {
    frameSerialNumber: string,
}

export function ThrowStageMapping(props: ThrowStageMappingProps) {
    const {frameSerialNumber} = props;
    const {data, isLoading, error} = useCompressorThrowStageMapping(frameSerialNumber);
    const grid = useRef<GridComponent>(null);
    const toolbarOptions: ToolbarItems[] = ['Search', 'ExcelExport'];
    const toolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.excelExport({fileName: `${frameSerialNumber}_ThrowStageMapping.xlsx`});
        }
    };

    const created = () => {
        document?.getElementById(grid.current?.element.id + "_searchbar")?.addEventListener('keyup', (event) => {
            grid.current?.search((event.target as HTMLInputElement).value)
        });
    };
    if (isLoading) {
        return (
            <GridSkeleton columns={2} rows={4}/>
        )
    }
    return (
        <div className="e-card throw-stage-mapping">
            <span className="e-card-header">Throw stage mapping</span>
            <GridComponent gridLines="Both" dataSource={data} allowReordering toolbarClick={toolbarClick} ref={grid}
                           id="throw-stage-mapping" allowMultiSorting allowSorting
                           toolbar={toolbarOptions} allowExcelExport created={created}>
                <ColumnsDirective>
                    <ColumnDirective headerText="Stage" field="stage"/>
                    <ColumnDirective headerText="Throw" field="throwNumber"/>
                    <ColumnDirective headerText="Model" field="cylinderModel"/>
                    <ColumnDirective headerText="Cylinder Action" field="cylinderAction"/>
                </ColumnsDirective>
                <Inject services={[Toolbar, Sort, ExcelExport]}/>
            </GridComponent>
        </div>
    );
}