import React from 'react';
import {AuthProvider as OidcAuthProvider} from "oidc-react";
import {useConfig} from "./ConfigProvider";

export function AuthProvider(props: { children: React.ReactNode }) {
    const config = useConfig();
    if(config.isLoading){
        return(<div/>)
    }
    return (
        <OidcAuthProvider {...config.oidcConfig}>
            {props.children}
        </OidcAuthProvider>
    );
}