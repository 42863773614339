import React, {MouseEventHandler, useEffect, useState} from 'react';
import {
    CylinderEnd,
    StageValveTempAndLeakIndexData,
    ValveTempAndLeakIndexData
} from "../../../../autogenerate/api.generated.clients";
import {
    AxisModel,
    Category,
    ChartComponent,
    ColumnSeries,
    DataLabel,
    MarkerSettingsModel,
    SeriesCollectionDirective,
    SeriesDirective,
    Tooltip,
    TooltipSettingsModel
} from "@syncfusion/ej2-react-charts";
import {Inject} from "@syncfusion/ej2-react-grids";
import {FlowDirection} from "../../../../enums";
import "./valveTempData.scss"

export interface ChartingValveTempData extends ValveTempAndLeakIndexData {
    chartX: string
}

export interface ValveTempDataProps {
    stages: StageValveTempAndLeakIndexData[] | undefined,
    onValveLabelClicked?: MouseEventHandler
}

export function ValveTempData(props: ValveTempDataProps) {
    const {stages, onValveLabelClicked} = props;
    const [isSuctionVisible, setIsSuctionVisible] = useState(true);
    const [isDischargeVisible, setIsDischargeVisible] = useState(true);
    let seriesPalette: string[] = ["#aaa", "#003e70"];
    let chartInstance: (ChartComponent | null)[] = [];

    useEffect(() => {
        chartInstance.forEach(chart => {
            if (chart) {
                chart.series.forEach(series => {
                    if (series.opacity && series.opacity < 1)
                        series.visible = isSuctionVisible;
                });
                seriesPalette = isDischargeVisible ? ["#aaa", "#003e70"] : ["#aaa"];
                chart.palettes = seriesPalette
                chart.refresh();
            }
        });
    }, [isSuctionVisible]);

    useEffect(() => {
        chartInstance.forEach(chart => {
            if (chart) {
                chart.series.forEach(series => {
                    if (series.opacity && series.opacity >= 1)
                        series.visible = isDischargeVisible;
                });
                seriesPalette = isDischargeVisible ? ["#aaa", "#003e70"] : ["#003e70"];
                chart.palettes = seriesPalette
                chart.refresh();
            }
        });
    }, [isDischargeVisible]);
    const primaryXAxis: AxisModel = {valueType: "Category", labelStyle: {fontWeight: '500', size: '1.25em'}};
    const primaryyAxis: AxisModel = {title: 'Temp (°F)'};
    const markerSettings: MarkerSettingsModel = {
        dataLabel: {
            visible: true,
            position: 'Top',
            font: {fontWeight: '600', color: '#ffffff'},
        }
    }

    const tooltip: TooltipSettingsModel = {
        enable: true, shared: true
    };

    const onSuctionLegendClick = (chartIndex: number) => {
        setIsSuctionVisible(!isSuctionVisible);
    }
    const onDischargeLegendClick = (chartIndex: number) => {
        setIsDischargeVisible(!isDischargeVisible);
    }
    const animationSettings = {enable: false};
    return (
        <div className="valve-temp-container">
            {stages?.sort((a, b) => a.stageNumber - b.stageNumber).map((stage, index) => {
                    const dataSets = getChartingDataSetsForStage(stage);
                    const numModerateLeaks = getNumModerateLeaks(stage);
                    const numCriticalLeaks = getNumCriticalLeaks(stage);
                    return (
                        <div className="e-card valve-data-container" key={`stage-${stage.stageNumber}-valve-temps-chart`}>
                            <div className="valve-temp-graph-header">
                                <h6 className="title">Stage {stage.stageNumber} valve cap temperatures</h6>
                                <div className="valve-leak-labels-container" onClick={onValveLabelClicked}>
                                    <span
                                        className={numCriticalLeaks > 0 ? `critical-leaks-label` : ""}>Critical leaks: {numCriticalLeaks}</span>
                                    <span
                                        className={numModerateLeaks > 0 ? `moderate-leaks-label` : ""}>Moderate leaks: {numModerateLeaks}</span>
                                </div>
                            </div>
                            <div>
                                <ChartComponent
                                    primaryYAxis={primaryyAxis}
                                    ref={chart => chartInstance.push(chart)}
                                    palettes={seriesPalette}
                                    id={`valve-cap-temps-${stage.stageNumber}`} primaryXAxis={primaryXAxis}
                                    tooltip={tooltip} width="99%">
                                    <Inject services={[ColumnSeries, Tooltip, Category, DataLabel]}/>
                                    <SeriesCollectionDirective>
                                        {isSuctionVisible ?
                                            dataSets.map((dataSet, index) => {
                                                const flow = dataSet[0]?.flowDirection;
                                                const valveNum = dataSet[0]?.valveNumber;
                                                //if else is necessary because suction series must not have width set in order for grouping to display properly
                                                if (flow == FlowDirection.Suction && isSuctionVisible) {
                                                    return (<SeriesDirective key={`data-series-${index}`}
                                                                             animation={animationSettings}
                                                                             groupName={`${valveNum}`}
                                                                             columnSpacing={.1} type="Column"
                                                                             opacity={0.6}
                                                                             dataSource={dataSet} xName="chartX"
                                                                             yName="tempF"
                                                                             marker={markerSettings}
                                                                             name={`${FlowDirection[flow]} ${valveNum}`}/>)
                                                } else if (isDischargeVisible) {
                                                    return (<SeriesDirective key={`data-series-${index}`}
                                                                             animation={animationSettings}
                                                                             groupName={`${valveNum}`} columnWidth={0.5}
                                                                             columnSpacing={.1} type="Column"
                                                                             dataSource={dataSet} xName="chartX"
                                                                             yName="tempF"
                                                                             marker={markerSettings}
                                                                             name={`${FlowDirection[flow]} ${valveNum}`}/>);
                                                }
                                            })
                                            : ""
                                        }
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                                <div className="valve-temp-chart-legend">
                                    <div className={`suction-valve-temp-legend ${!isSuctionVisible ? 'faded' : ''}`}
                                         onClick={() => onSuctionLegendClick(index)}/>
                                    <div onClick={() => onSuctionLegendClick(index)}
                                         className={!isSuctionVisible ? 'faded' : ''}>Suction
                                    </div>
                                    <div className={`discharge-valve-temp-legend ${!isDischargeVisible ? 'faded' : ''}`}
                                         onClick={() => onDischargeLegendClick(index)}/>
                                    <div onClick={() => onDischargeLegendClick(index)}
                                         className={!isDischargeVisible ? 'faded' : ''}>Discharge
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            )}
        </div>
    );
}

function getNumCriticalLeaks(stage: StageValveTempAndLeakIndexData) {
    return stage.throwValveTempAndLeakIndexData.reduce((accumulator: number, currentThrow) => {
        return accumulator + currentThrow.valveTempAndLeakIndexData.reduce((valveAccumulator: number, valve) => {
            if (valve.leakIndex > 1) {
                return valveAccumulator + 1;
            }
            return valveAccumulator;
        }, 0)
    }, 0);
}

function getNumModerateLeaks(stage: StageValveTempAndLeakIndexData) {
    return stage.throwValveTempAndLeakIndexData.reduce((accumulator: number, currentThrow) => {
        return accumulator + currentThrow.valveTempAndLeakIndexData.reduce((valveAccumulator: number, valve) => {
            if (valve.leakIndex > .5 && valve.leakIndex <= 1) {
                return valveAccumulator + 1;
            }
            return valveAccumulator;
        }, 0)
    }, 0);
}

function getChartingValvesForStage(stage: StageValveTempAndLeakIndexData) {
    return stage.throwValveTempAndLeakIndexData.reduce((accumulator: ChartingValveTempData[], currentThrow) => {
        return [...accumulator, ...currentThrow.valveTempAndLeakIndexData.map((valve: ValveTempAndLeakIndexData) => {
            const chartValve = valve as ChartingValveTempData;
            chartValve.chartX = `Throw ${currentThrow.throwNumber} ${CylinderEnd[valve.cylinderEnd]}`
            return chartValve;
        })]
    }, []);
}

function getMaxNumValves(valves: ChartingValveTempData[]) {
    return Math.max(...valves.map((currentValve) => currentValve.valveNumber))
}

function getChartingDataSetsForStage(stage: StageValveTempAndLeakIndexData) {
    const valves = getChartingValvesForStage(stage);
    const maxValvesPerEnd = getMaxNumValves(valves);
    const dataSets: ChartingValveTempData[][] = [];
    for (let i = 1; i <= maxValvesPerEnd; i++) {
        dataSets.push(valves.filter((valve) => valve.valveNumber == i && valve.flowDirection == FlowDirection.Discharge));
        dataSets.push(valves.filter((valve) => valve.valveNumber == i && valve.flowDirection == FlowDirection.Suction));
    }

    return dataSets;
}