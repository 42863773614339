import React, {useRef} from 'react';
import {useCylinderTelemetryProperties} from "../../../../hooks/Compressor/Telemetry/useCylinderTelemetryProperties";
import {GridSkeleton} from "../../../../components/Skeletons/GridSkeleton";
import {
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    FilterSettingsModel,
    GridComponent,
    Inject,
    PdfExport,
    Resize,
    Search,
    Sort,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";

export interface CylinderTelemetryDisplayProps extends React.HTMLProps<HTMLDivElement> {
    cylinderSN: string,
    isPositionHE?: boolean,
    frameSerialNumber: string
}

export function CylinderTelemetryDisplay(props: CylinderTelemetryDisplayProps) {
    const {cylinderSN, frameSerialNumber, isPositionHE, ...reactProps} = props;
    const {data, isLoading} = useCylinderTelemetryProperties({
        cylinderSN,
        frameSerialNumber,
        isPositionHE: isPositionHE ? true : false
    })
    const grid = useRef<GridComponent>(null);

    if (isLoading) {
        return <div className={`e-card ${reactProps.className}`}>
            <GridSkeleton columns={4} rows={10}/>
        </div>
    }
    const toolbarOptions = ['Search', 'ExcelExport'];
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    const dataBound = () => {
        if (grid) {
            grid.current?.autoFitColumns(['displayName']);
        }
    };
    const toolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.excelExport({fileName: `${props.cylinderSN}_Telemetry.xlsx`});
        }
    };
    const created = () => {
        document?.getElementById(grid.current?.element.id + "_searchbar")?.addEventListener('keyup', (event) => {
            grid.current?.search((event.target as HTMLInputElement).value)
        });
    };
    return (
        <div {...reactProps} className="cylinder-telemetry-display e-card">
            <span className="e-card-header">Cylinder {cylinderSN}</span>
            <GridComponent id="compressorGrid" allowMultiSorting allowSorting
                           ref={grid} toolbarClick={toolbarClick}
                           created={created}
                           gridLines="Both"
                           dataSource={data}
                           dataBound={dataBound}
                           toolbar={toolbarOptions} enableStickyHeader
                           filterSettings={filterOptions}
                           allowExcelExport
                           loadingIndicator={{indicatorType: 'Shimmer'}}>
                <ColumnsDirective>
                    <ColumnDirective field="displayName" headerText="Property"/>
                    <ColumnDirective field="measured" width="auto" textAlign="Right" headerText="Measured"/>
                    <ColumnDirective field="calculated" width="auto" textAlign="Right" headerText="Calculated"/>
                    <ColumnDirective field="difference" width="auto" format="P" textAlign="Right"
                                     headerText="% Difference"/>
                </ColumnsDirective>
                <Inject services={[Toolbar, Resize, Search, Sort, ExcelExport, PdfExport]}/>
            </GridComponent>
        </div>
    );
}