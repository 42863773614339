import React, {useEffect} from 'react';
import {
    TabAnimationSettingsModel,
    TabComponent,
    TabItemDirective,
    TabItemsDirective
} from "@syncfusion/ej2-react-navigations";
import {useActiveCompressorAlerts} from "../../../hooks/Compressor/useCompressorAlerts";
import {AlertsGrid} from "../../../components/AlertsGrid/AlertsGrid";
import {AlertSetPointsGrid} from "../AlertSetPointsGrid/AlertSetPointsGrid";
import {CompressorResolvedAlertsTabContent} from "./CompressorResolvedAlertsTab/CompressorResolvedAlertsTabContent";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";


function CompressorAlertsTab() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber;
    const activeAlertsData = useActiveCompressorAlerts({frameId: frameSerialNumber!});
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/compressors/${frameSerialNumber}/alerts`, title: "Compressor Alerts" });
    }, []);
    
    if (!frameSerialNumber)
        return (<div>Compressor not found</div>)


    const activeAlertsTab = () => <AlertsGrid data={activeAlertsData.data} isLoading={activeAlertsData.isLoading}
                                              hiddenColumns={["resolutionTimeStamp"]}/>
    const alertSetPointsTab = () => <AlertSetPointsGrid frameSerialNumber={frameSerialNumber} />
    const resolvedAlertsTab = () => <CompressorResolvedAlertsTabContent frameId={frameSerialNumber}/>


    const tabAnimation: TabAnimationSettingsModel = {
        previous: {effect: 'None'},
        next: {effect: 'None'}
    };
    return (
        <div className="compressor-landing-page-container">
            <TabComponent heightAdjustMode="Auto" animation={tabAnimation}
                          cssClass="compressor-landing-alert-tabs">
                <TabItemsDirective>
                    <TabItemDirective headerText="Active Alerts" content={activeAlertsTab}/>
                    <TabItemDirective headerText="Resolved Alerts" content={resolvedAlertsTab}/>
                    <TabItemDirective headerText="Alert Set Points" content={alertSetPointsTab}/>
                </TabItemsDirective>
            </TabComponent>
        </div>
    );
}

export default CompressorAlertsTab;