import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Typography } from "@mui/material";

interface Props {
    children: ReactNode;
    errorMessage?: string;
    errorComponent?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.errorComponent ? (
                this.props.errorComponent
            ) : (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography color={'red'}>
                        {this.props.errorMessage || "Failed to create component"}
                    </Typography>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;