import React, {useEffect, useState} from 'react';
import {useAuth} from "oidc-react";
import {setCommonAuthHeader} from "../services/axios.service";
import {Typography} from "@mui/material";
import "./authLoader.scss";
import {AppBarComponent} from "@syncfusion/ej2-react-navigations";
import SubmitLink from "@mui/material/Link";
import {SubmitFeedbackUrl} from "../constants";
import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import ReactGA from "react-ga4";

interface AuthProps {
    children: React.ReactNode
}

export function AuthLoader(props: AuthProps) {
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        if (auth.userData?.access_token) {
            ReactGA.gtag("set", "user_properties", {
                is_ariel_employee: auth.userData.profile.email?.includes("@arielcorp.com") ?? false,
            });
            
            setCommonAuthHeader(auth.userData.access_token)
            setIsLoading(false);
        }
    }, [auth])

    if (isLoading)
        return (
            <div className='error-container'>
                <AppBarComponent colorMode="Primary" id="main-appbar">
                </AppBarComponent>
            </div>);
    
    if (!auth.userData?.access_token)
        return (
            <div className='error-container'>
                <AppBarComponent colorMode="Primary" id="main-appbar">
                    <ButtonComponent cssClass='e-inherit menu'>
                        <SubmitLink href='https://www.arielcorp.com/support/software-and-apps/fleet-manager.html'>
                            Ariel Fleet Manager
                        </SubmitLink>
                    </ButtonComponent>
                    <ButtonComponent cssClass='e-inherit menu'>
                        <SubmitLink href={SubmitFeedbackUrl} target='_blank' rel="noopener">
                            Submit Feedback
                        </SubmitLink>
                    </ButtonComponent>
                </AppBarComponent>
                <Typography className='signin-error'>
                    There was an error authenticating the user during single sign on.
                </Typography>
            </div>);

    return (
        <>{props.children}</>
    );
}