import {useQuery} from "react-query";
import {Client, VolumeUnits} from "../../../autogenerate/api.generated.clients";

export interface UseLubeOilDataProps {
    frameSerialNumber: string
    lubeOilVolumeUnits: VolumeUnits
}

export const useLubeOilData = (props: UseLubeOilDataProps) => {
    //ts ignore is required here after .net8 upgrade because for some reason the autogenerated controller functions think they expect a string where Enum is required
    //this is not an issue in earlier version of .net
    //@ts-ignore
    return useQuery(["lube-oil-data", props.frameSerialNumber, props.lubeOilVolumeUnits], () => new Client().compressor_GetLubeOilConditions(props.frameSerialNumber, props.lubeOilVolumeUnits))
}