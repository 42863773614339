import React from 'react';
import {
    AnnotationDirective,
    Annotations,
    AnnotationsDirective,
    AxesDirective,
    AxisDirective,
    CircularGaugeComponent,
    Gradient,
    PointerDirective,
    PointersDirective,
    RangeDirective,
    RangesDirective
} from "@syncfusion/ej2-react-circulargauge";
import {usePredictedValueRanges} from "../../hooks/Compressor/usePredictedValueRanges";
import {Inject} from "@syncfusion/ej2-react-grids";
import './pressureGauge.css';

export interface PressureGaugeProps {
    value: number,
    expectedValue: number,
    title?: string | undefined
}

export function PressureGauge(props: PressureGaugeProps) {
    const {value, expectedValue, title} = props;
    const ranges = usePredictedValueRanges(expectedValue);
    const rangeWidth = 6;
    return (
        <CircularGaugeComponent width="200" height="250">
            <Inject services={[Annotations, Gradient]}/>
            <AxesDirective>
                <AxisDirective minimum={ranges.error?.min} maximum={ranges.error?.max}
                               startAngle={210} endAngle={150}
                               majorTicks={{
                                   color: '#303030',
                                   width: 1,
                                   height: 10,
                                   offset: 7,
                                   interval: (ranges.error?.max - ranges.error?.min) / 6
                               }}
                               minorTicks={{
                                   color: '#505050',
                                   width: 1,
                                   offset: 8,
                                   interval: (ranges.error?.max - ranges.error?.min) / 30
                               }}
                               labelStyle={{offset: -5, font: {size: "11px"}, format: 'n0'}}>
                    <PointersDirective>
                        <PointerDirective radius="110%" value={value} pointerWidth={15} color="#303030"
                                          animation={{enable: false, duration: 0}}
                                          cap={{radius: 35, color: "#fff", border: {color: '#ccc', width: 1}}}/>
                    </PointersDirective>
                    <AnnotationsDirective>
                        <AnnotationDirective content={`<div class="pressure-gauge-title">${title ?? ""}</div>`}
                                             angle={0} radius='130%' zIndex='1'/>
                        <AnnotationDirective
                            content={`<div class="pressure-gauge-value-container"><div class="pressure-gauge-value">${value}</div><div class="pressure-gauge-units">psi</div></div>`}
                            angle={180} radius='8%' zIndex='1'/>
                        <AnnotationDirective content={`<div class="pressure-gauge-subtitle">Pressure</div>`} angle={175}
                                             radius='85%' zIndex='1'/>
                    </AnnotationsDirective>
                    <RangesDirective>
                        <RangeDirective start={ranges.error?.min} end={ranges.warning?.min} color="#F03E3E"
                                        radius='100%' startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '100%', y: '100%'},
                            outerPosition: {x: '100%', y: '100%'},
                            colorStop: [
                                {color: '#F03E3E', offset: '50%', opacity: 1},
                                {color: '#FFDD00', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.warning?.min} end={ranges.ok?.min} color="#FFDD00" radius='100%'
                                        startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '0%', y: '100%'},
                            outerPosition: {x: '100%', y: '100%'},
                            colorStop: [
                                {color: '#FFDD00', offset: '50%', opacity: 1},
                                {color: '#30B32D', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.ok?.min} end={ranges.ok?.max} color="#30B32D" radius='100%'
                                        startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '50%', y: '0%'},
                            outerPosition: {x: '50%', y: '0%'},
                            colorStop: [
                                {color: '#30B32D', offset: '40%', opacity: 1},
                                {color: '#FFDD00', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.ok?.max} end={ranges.warning?.max} color="#FFDD00" radius='100%'
                                        startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '140%', innerPosition: {x: '0%', y: '100%'},
                            outerPosition: {x: '100%', y: '100%'},
                            colorStop: [
                                {color: '#FFDD00', offset: '50%', opacity: 1},
                                {color: '#30B32D', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                        <RangeDirective start={ranges.warning?.max} end={ranges.error?.max} color="#F03E3E"
                                        radius='100%' startWidth={rangeWidth} endWidth={rangeWidth} radialGradient={{
                            radius: '100%', innerPosition: {x: '10%', y: '70%'},
                            outerPosition: {x: '100%', y: '100%'},
                            colorStop: [
                                {color: '#F03E3E', offset: '50%', opacity: 1},
                                {color: '#FFDD00', offset: '100%', opacity: 0.9}
                            ]
                        }}/>
                    </RangesDirective>
                </AxisDirective>
            </AxesDirective>
        </CircularGaugeComponent>
    );
}