import React, {useEffect, useState} from 'react';
import {Badge, Checkbox, Divider, IconButton, Menu, MenuItem, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChartAxisChooser from "../AxisChooser/ChartAxisChooser";
import {ChartingVariable, CompressorAlert} from "../../../autogenerate/api.generated.clients";
import AlertSelectionPopup from "../../AlertSelectionPopup/AlertSelectionPopup";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";

interface ChartSettingsMenuProps {
    anchorEl: HTMLElement | null,
    isOpen: boolean,
    onClose: ()=>void,
    leftSeries: ChartingVariable[],
    rightSeries: ChartingVariable[],
    alerts: CompressorAlert[] | undefined,
    onLeftSeriesChanged: (leftVariables: ChartingVariable[])=>void,
    onRightSeriesChanged: (rightVariables: ChartingVariable[])=>void,
    onOverlayWarningsChanged: (selected: boolean)=>void,
    onOverlayShutdownsChanged: (selected: boolean)=>void
    onAlertsSelectedForOverlay: (selectedAlerts: CompressorAlert[])=>void
}

function ChartSettingsMenu(props: ChartSettingsMenuProps) {
    const params = useParams();
    const {alerts, anchorEl, isOpen, leftSeries, rightSeries} = props
    const [isAlertSelectionOpen, setIsAlertSelectionOpen] = useState(false);
    const [isChartSettingsOpen, setIsChartSettingsOpen] = useState(false);
    const [overlayFaultsAnchorEl, setOverlayFaultsAnchorEl] = useState<null | HTMLElement>(null);
    const [isOverlayShutdownsSelected, setIsOverlayShutdownsSelected] = useState(false);
    const [isOverlayWarningsSelected, setIsOverlayWarningsSelected] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState<CompressorAlert[]>([])

    useEffect(() => {
        setSelectedAlerts([]);
    }, [params.frameSerialNumber]);
    
    useEffect(() => {
        props.onOverlayWarningsChanged(isOverlayWarningsSelected)
        if(isOverlayWarningsSelected) {
            ReactGA.event("overlay_alerts", {category: "Warning"})
        }
    }, [isOverlayWarningsSelected]);

    useEffect(() => {
        props.onOverlayShutdownsChanged(isOverlayShutdownsSelected)
        if(isOverlayShutdownsSelected) {
            ReactGA.event("overlay_alerts", {category: "Shutdown"})
        }
    }, [isOverlayShutdownsSelected]);

    useEffect(() => {
        if(anchorEl == null)
        {
            setOverlayFaultsAnchorEl(null);
        }
    }, [anchorEl]);
    const handleIconMenuClose = () => {
        props.onClose();
    }
    
    const onAlertsSelected = (alerts: CompressorAlert[]): void=>{
        props.onAlertsSelectedForOverlay(alerts);
        setSelectedAlerts(alerts);
        setIsAlertSelectionOpen(false);
        ReactGA.event("overlay_alerts", {category : "Custom"})
    }
    
    return (
        <Menu
            anchorEl={anchorEl}
            id="advanced-charting-options"
            open={isOpen}
            onClose={handleIconMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={() => setIsChartSettingsOpen(true)}>
                Choose Axes
            </MenuItem>
            <Divider/>
            <MenuItem onClick={(event) => setOverlayFaultsAnchorEl(event.currentTarget)}>
                Overlay Alerts 
                <ArrowRightIcon/>
                <Menu
                    sx={{pointerEvents: 'none'}}
                    slotProps={{paper:{style:{pointerEvents: 'all'}}}}
                    anchorEl={overlayFaultsAnchorEl}
                    id="advanced-charting-options"
                    open={Boolean(overlayFaultsAnchorEl)}
                    onClose={()=>setOverlayFaultsAnchorEl(null)}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem onClick={(e)=>{
                        e.preventDefault();
                        setIsOverlayShutdownsSelected(!isOverlayShutdownsSelected);
                    }}>
                        <Checkbox style={{padding: 0, paddingRight: '.5em'}} size={'small'} checked={isOverlayShutdownsSelected} />
                        Shutdown Alerts
                    </MenuItem>
                    <MenuItem onClick={(e)=> setIsOverlayWarningsSelected(!isOverlayWarningsSelected)}>
                        <Checkbox style={{padding: 0, paddingRight: '.5em'}} size={'small'} checked={isOverlayWarningsSelected} />
                        Warning Alerts
                    </MenuItem>
                    <Divider/>
                    <MenuItem onClick={() => setIsAlertSelectionOpen(true)}>
                        <Badge invisible={selectedAlerts.length ===0} badgeContent={selectedAlerts.length} color={'info'} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                            Select Alerts
                        </Badge>
                    </MenuItem>
                </Menu>
            </MenuItem>
            <Modal open={isChartSettingsOpen} 
                   onClose={()=>{
                       setIsChartSettingsOpen(false);
                       handleIconMenuClose();
                       ReactGA.event("edit_chart_axis")
                   }}>
                <div style={{position: 'relative',
                    top: '50%',
                    left: '50%',
                    width: '50%',
                    height: '50%',
                    padding: '1em',
                    borderRadius: '5px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white'}}>
                    <IconButton style={{position: 'absolute', top: '.5em', right: '.5em'}} aria-label="close filters" size="small" 
                                onClick={()=>{
                                    setIsChartSettingsOpen(false);
                                    handleIconMenuClose();
                                }}>
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                    <ChartAxisChooser leftAxisVariables={leftSeries}
                                      rightAxisVariables={rightSeries}
                                      onUpdateLeftAxisVariables={(variables)=>props.onLeftSeriesChanged(variables)}
                                      onUpdateRightAxisVariables={(variables)=>props.onRightSeriesChanged(variables)}/>
                </div>
            </Modal>
            <Modal open={isAlertSelectionOpen} 
                   onClose={()=>{
                       setIsAlertSelectionOpen(false);
                       handleIconMenuClose();
                   }}>
                <div style={{position: 'relative',
                    top: '50%',
                    left: '50%',
                    width: '50%',
                    height: '70%',
                    padding: '1.5em',
                    borderRadius: '5px',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white'}}>
                    <IconButton style={{position: 'absolute', top: '.5em', right: '.5em'}} aria-label="close filters" size="small" 
                                onClick={()=>{
                                    setIsAlertSelectionOpen(false);
                                    handleIconMenuClose();
                                }}>
                        <CloseIcon fontSize="inherit"/>
                    </IconButton>
                    <AlertSelectionPopup selectedAlerts={selectedAlerts} alerts={alerts} onAlertsSelected={onAlertsSelected} />
                </div>
            </Modal>
        </Menu>
    );
}

export default ChartSettingsMenu;