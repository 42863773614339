import * as React from "react";
import {useEffect, useState} from "react";
import {useAlertSetPoints} from "../../../hooks/Compressor/useAlertSetPoints";
import {getComparator, Order, stableSort} from "../../../services/muiTableSort.service";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from "@mui/material";
import {AppManualUrl, PackagerStandardsUrl} from "../../../constants";
import ExternalNavLinks, {Link} from "../../../components/ExternalNavLinks/ExternalNavLinks";
import {CompressorPropertyWithValue} from "../../../autogenerate/api.generated.clients";


const setpointLinks = [{displayName: "Application Manual", linkAddress:AppManualUrl}, {displayName:"Packager Standards", linkAddress:PackagerStandardsUrl}] as Link[]
const linkDescription = "For more information about operational set points, refer to Ariel product documentation: "
interface AlertSetPointsGridProps {
    frameSerialNumber: string
}

interface HeadCell {
    id: keyof CompressorPropertyWithValue,
    label: string,
    numeric: boolean
}

interface EnhancedTableProps {
    headCells: HeadCell[],
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CompressorPropertyWithValue) => void;
    order: Order,
    orderBy: string
}

export function AlertSetPointsGrid(props: AlertSetPointsGridProps) {
    const {frameSerialNumber} = props;
    const {data, isLoading, error} = useAlertSetPoints(frameSerialNumber);
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<keyof CompressorPropertyWithValue>("displayName");
    const [rows, setRows] = useState<CompressorPropertyWithValue[]>([] as CompressorPropertyWithValue[]);
    const [filterText, setFilterText] = useState<string>("");
    const headCells: HeadCell[] = [
        {
            id: "displayName",
            numeric: false,
            label: "Set Point Description"
        },
        {
            id:"value",
            numeric: true,
            label: "Set Point Value"
        },
        {
            id: "unitOfMeasure",
            numeric: false,
            label: "Measurement Unit"  
        },
        {
            id: "ascPropertyName",
            numeric: false,
            label: "Set Point"
        }
    ]

    useEffect(() => {
        if(data) {
            setRows(data);
        }
    }, [data]);

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    }

    useEffect(() => {
        if(data !== undefined) {
            let filteredRows = data.filter(row => {
                return ((
                    row.displayName.toString().toLowerCase().includes(filterText.toLowerCase())
                    || row.ascPropertyName.toString().toLowerCase().includes(filterText.toLowerCase())
                    || row.value.toString().includes(filterText)
                ))
            })
            setRows(filteredRows);
        }
    }, [filterText]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CompressorPropertyWithValue) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, rows]
    );

    return (
        <Box sx={{ width: '100%' }} >
            <TextField
                id='set-points-search'
                label='Filter Set Points'
                sx={{width: '97%', margin: '1em'}}
                value={filterText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {handleFilter(e)}}
            />
            <Paper sx={{width:'100%', mb:2}}>
               <TableContainer sx={{maxHeight: '60vh'}}>
                   <Table stickyHeader sx={{minWidth: 750}}>
                       <EnhancedTableHead
                           headCells={headCells}
                           onRequestSort={handleRequestSort}
                           order={order}
                           orderBy={orderBy}
                       />
                       <TableBody>
                           {
                               isLoading?
                                    <TableRow/>
                               :
                                   visibleRows.map((row, index) => {
                                       return (
                                           <TableRow key={row.ascPropertyName}>
                                               <TableCell id={`${row.ascPropertyName}-description`}>{row.displayName}</TableCell>
                                               <TableCell id={`${row.ascPropertyName}-value`}>{row.value}</TableCell>
                                               <TableCell id={`${row.ascPropertyName}-measurement-unit`}>{row.unitOfMeasure}</TableCell>
                                               <TableCell id={`${row.ascPropertyName}`}>{row.ascPropertyName}</TableCell>
                                           </TableRow>
                                       )
                                   })
                           }
                       </TableBody>
                   </Table>
               </TableContainer>
            </Paper>
            <ExternalNavLinks linkDescription={linkDescription} links={setpointLinks}/>
        </Box>
    )
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {headCells, onRequestSort, order, orderBy} = props;
    const createSortHandler = (property: keyof CompressorPropertyWithValue) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id as keyof CompressorPropertyWithValue)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={{display: 'none'}}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}