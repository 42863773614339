import React from 'react';
import {
    TabAnimationSettingsModel,
    TabComponent,
    TabItemDirective,
    TabItemsDirective
} from "@syncfusion/ej2-react-navigations";
import './reportsLanding.scss'
import SoftwareVersionFleetReport from "./SoftwareVersionFleetReport/SoftwareVersionFleetReport";

function ReportsLanding() {
    const tabAnimation: TabAnimationSettingsModel = {
        previous: {effect: 'None'},
        next: {effect: 'None'}
    };
    const softwareVersionFleetReportTab = () => <SoftwareVersionFleetReport/>
    return (
        <div className='reports-landing-page-container'>
            <TabComponent animation={tabAnimation} className='report-landing-tabs'>
                <TabItemsDirective>
                    <TabItemDirective headerText='Software Version Fleet Report'
                                      content={softwareVersionFleetReportTab}/>
                </TabItemsDirective>
            </TabComponent>
        </div>
    );
}

export default ReportsLanding;