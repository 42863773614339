import React, {useState} from 'react';
import {useLubeOilData} from "../../../../hooks/Compressor/LubeOil/useLubeOilData";
import './compressorLubeOilCards.scss'
import {MultiValueDashboardCard} from "../../../../components/DashboardCard/MultiValueDashboardCard";
import {VolumeUnits} from "../../../../autogenerate/api.generated.clients";
import {MenuEventArgs, MenuItemModel} from "@syncfusion/ej2-react-navigations";
import {DashboardCard} from "../../../../components/DashboardCard/DashboardCard";

export interface CompressorLubeOilCardsProps {
    frameSerialNumber: string
}

export function CompressorLubeOilCards(props: CompressorLubeOilCardsProps) {
    const {frameSerialNumber} = props;
    const [lubeOilVolumeUnits, setLubeOilVolumeUnits] = useState(VolumeUnits.Gallons);
    const {data, isLoading, error} = useLubeOilData({frameSerialNumber, lubeOilVolumeUnits});
    const oilMenuOptions: MenuItemModel[] = Object.keys(VolumeUnits).filter(key => isNaN(Number(key))).map(key => {
        return {text: key}
    })
    const onVolumeSelected = (args: MenuEventArgs) => {
        if (args?.item?.text) {
            // @ts-ignore
            setLubeOilVolumeUnits(VolumeUnits[args.item.text]);
        }
    }
    return (
        <div className="lube-oil-cards-container">
            <MultiValueDashboardCard className={'col-span-2'} isLoading={isLoading} onContextMenuSelected={onVolumeSelected}
                                     contextMenuOptions={oilMenuOptions}
                                     title={`Total Oil Consumption`}
                                     labelValuePairs={[{
                                         label: 'Per day',
                                         value: `${data?.totalOilConsumption_PerDay} ${getAbbreivationForVolume(data?.volumeUnits)}`
                                     }, {
                                         label: 'Per week',
                                         value: `${data?.totalOilConsumption_PerWeek} ${getAbbreivationForVolume(data?.volumeUnits)}`
                                     }]}/>
            <MultiValueDashboardCard className={'col-span-2'} isLoading={isLoading} onContextMenuSelected={onVolumeSelected}
                                     contextMenuOptions={oilMenuOptions}
                                     title={`Oil consumption per day`}
                                     labelValuePairs={[{
                                         label: 'Even throws',
                                         value: `${data?.dailyOilConsumption_EvenThrows} ${getAbbreivationForVolume(data?.volumeUnits)}`
                                     }, {
                                         label: 'Odd throws',
                                         value: `${data?.dailyOilConsumption_OddThrows} ${getAbbreivationForVolume(data?.volumeUnits)}`
                                     }]}/>
            <MultiValueDashboardCard className={'col-span-2'} isLoading={isLoading} title="Average cycle time" labelValuePairs={[{
                label: 'Even throws',
                value: `${data?.averageCyleTimeSeconds_EvenThrows} sec`
            }, {label: 'Odd throws', value: `${data?.averageCyleTimeSeconds_OddThrows} sec`}]}/>
            <DashboardCard cardClassName='rpm-card' isLoading={isLoading} label={"Current Speed"} value={`${data?.lastRpm} RPM`}/>
        </div>
    );
}

const getAbbreivationForVolume = (units: VolumeUnits | undefined) => {
    switch (units) {
        case VolumeUnits.Liters:
            return "L";
        case VolumeUnits.Pints:
            return "pt";
        default:
            return "Gal";
    }
}