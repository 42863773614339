import React from 'react';
import {Button, Typography} from "@mui/material";
import useTermsAndConditions from "../../hooks/TermsAndConditions/useTermsAndConditions";
import './termsAndConditionsPage.scss'
import {TermsAndConditionsStatus} from "../../autogenerate/api.generated.clients";
import {useUpdateTermsAndConditionsStatus} from "../../hooks/TermsAndConditions/useUpdateTermsAndConditionsStatus";

export interface TermsAndConditionsPageProps {
    onAccept: Function
}

function TermsAndConditionsPage(props: TermsAndConditionsPageProps) {
    const {data, isLoading} = useTermsAndConditions();
    const {updateTermsAndConditionsStatus} = useUpdateTermsAndConditionsStatus();

    const onAcceptClicked = async () => {
        await updateTermsAndConditionsStatus(TermsAndConditionsStatus.Accepted);
        props.onAccept();
    }

    return (
        <div className='terms-and-conditions'>
            <div>
                <h2>Terms and Conditions:</h2>
                <Typography color='gray'>
                    In order to use Ariel Fleet Manager you need to accept the following terms and conditions
                </Typography>
            </div>
            <div className='terms-text'>
                {data?.text}

            </div>
            <div className='buttons-container'>
                <Button variant='contained' aria-label='Accept Button' onClick={() => onAcceptClicked()}>
                    Accept terms and conditions
                </Button>
                <a href='https://www.arielcorp.com'>
                    <Button variant='text' aria-label='Decline Button'>
                        Back to Ariel website
                    </Button>
                </a>
            </div>
        </div>
    );
}

export default TermsAndConditionsPage;