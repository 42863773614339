import React from 'react';
import {ISerialNumberedEquipment} from "../../../autogenerate/api.generated.clients";
import './Unloader.scss'
import unloaderImage from './images/Unloader.png';
import selectedImage from './images/UnloaderSelected.png';
import {UnloaderType} from "../../../enums";

export interface UnloaderProps extends React.HTMLProps<Element> {
    equipment: ISerialNumberedEquipment,
    isSelected?: boolean,
    isEvenThrow?: boolean,
    equipmentSelected?: Function,
    isDisabled?: boolean
}

export function Unloader(props: UnloaderProps) {
    const {equipment, isEvenThrow, isSelected, isDisabled} = props;
    const unloaderData = equipment as any;
    const onClick = () => {
        if (props.equipmentSelected && !isDisabled) {
            props.equipmentSelected(equipment);
        }
    }
    const unloderInfo = <div className="unloader-data">
        <div>{unloaderData.serialNumber}</div>
        <div>{UnloaderType[unloaderData.unloaderType]}</div>
    </div>;

    return (
        <div style={props.style}
             className={`unloader-container ${props.className} ${isEvenThrow ? 'even-throw' : 'odd-throw'} ${isDisabled ? 'disabled' : 'enabled'} ${isSelected ? 'selected' : ''}`}
             onClick={onClick}>
            {!isEvenThrow ?
                unloderInfo
                : ""
            }
            <img src={isSelected ? selectedImage : unloaderImage} style={{width: '50%', height: '50%'}}/>
            {isEvenThrow ?
                unloderInfo
                : ""
            }
        </div>
    );
}