import React from 'react';
import {
    TabAnimationSettingsModel,
    TabComponent,
    TabItemDirective,
    TabItemsDirective
} from "@syncfusion/ej2-react-navigations";
import {useUserParentOrganizations} from "../../hooks/Organization/useUserParentOrganizations";
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import UnauthorizedAlert from "../../components/UnauthorizedAlert/UnauthorizedAlert";
import {ApiException} from "../../autogenerate/api.generated.clients";
import OrganizationManager from './OrganizationManager/OrganizationManager';

function AdministrationLanding() {
    const tabAnimation: TabAnimationSettingsModel = {
        previous: {effect: 'None'},
        next: {effect: 'None'}
    };

    const parentOrganizations = useUserParentOrganizations();
    if (parentOrganizations.isLoading) {
        return <SkeletonComponent/>
    }
    if (parentOrganizations.isError && parentOrganizations.error instanceof ApiException && parentOrganizations.error.status == 403) {
        return (<UnauthorizedAlert/>);
    }
    const organizationManagementTab = () => <OrganizationManager organizations={parentOrganizations.data!}/>


    return (
        <div className='reports-landing-page-container'>
            <div>
                <TabComponent animation={tabAnimation} className='report-landing-tabs'>
                    <TabItemsDirective>
                        <TabItemDirective headerText='Organization Management' content={organizationManagementTab}/>
                    </TabItemsDirective>
                </TabComponent>
            </div>
        </div>
    );
}

export default AdministrationLanding;