import React, {useRef, useState} from 'react';
import {
    CylinderEnd,
    StageValveTempAndLeakIndexData,
    ValveTempAndLeakIndexData
} from "../../../../autogenerate/api.generated.clients";
import {
    ColumnDirective,
    ColumnsDirective,
    Edit,
    ExcelExport,
    FilterSettingsModel,
    GridComponent,
    Inject,
    PdfExport,
    Reorder,
    Resize,
    Sort,
    SortSettingsModel,
    TextWrapSettingsModel,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import {FlowDirection, ValveLeakSeverity} from "../../../../enums";
import './valveLeakData.scss';
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import {ValveStatusIcon} from "../ValveStatusIcon/ValveStatusIcon";

export interface ChartingValveLeakIndexData extends ValveTempAndLeakIndexData {
    displayName: string,
    stageNumber: number,
    leakSeverity: ValveLeakSeverity,
}

export interface ValveLeakDataProps {
    stages: StageValveTempAndLeakIndexData[] | undefined
}

export function ValveLeakData(props: ValveLeakDataProps) {
    const {stages} = props;
    const [valveLeakData, setValveLeakData] = useState(getValvesForLeakIndexList(stages));
    const grid = useRef<GridComponent>(null);
    const toolbarOptions = ['ExcelExport'];
    const filterOptions: FilterSettingsModel = {type: 'Excel'};

    const dataBound = () => {
        if (grid) {
            grid.current?.autoFitColumns(['stageNumber', 'leakIndex', 'leakIndexPercent']);
        }
    };
    const toolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.showSpinner();
            grid.current?.excelExport({fileName: 'ASC_FleetStatus.xlsx'});
        }
    }

    const valveStatusTemplate = (props: any) => {
        return (<div className='valve-status-icon'>
            <ValveStatusIcon leakSeverity={props.leakSeverity}/>
        </div>);
    }
    const sortingOptions: SortSettingsModel = {
        columns: [{field: 'leakIndex', direction: 'Descending'}]
    };
    const textWrapSettings: TextWrapSettingsModel = {wrapMode: "Header"}

    return (
        <div className="valve-leak-tab-container">
            <GridComponent className="leak-index-grid"
                           width="100%" height="100%"
                           ref={grid}
                           enableStickyHeader
                           dataSource={valveLeakData}
                           allowResizing
                           allowSorting
                           statelessTemplates={['directiveTemplates']}
                           sortSettings={sortingOptions}
                           allowTextWrap
                           textWrapSettings={textWrapSettings}
                           toolbar={toolbarOptions}
                           filterSettings={filterOptions} dataBound={dataBound} allowReordering
                           toolbarClick={toolbarClick} allowExcelExport
                           loadingIndicator={{indicatorType: 'Shimmer'}}>
                <ColumnsDirective>
                    <ColumnDirective template={valveStatusTemplate} width="75px" textAlign="Left" headerText=""/>
                    <ColumnDirective field="stageNumber" textAlign="Left" headerText="Stage"/>
                    <ColumnDirective field="displayName" width="auto" textAlign="Left" headerText="Valve"/>
                    <ColumnDirective field="leakIndex" textAlign="Right" headerText="Leak Index"/>
                </ColumnsDirective>
                <Inject services={[Toolbar, Resize, Sort, Reorder, ExcelExport, PdfExport, Edit]}/>
            </GridComponent>
        </div>
    );
}

function getValvesForLeakIndexList(nestedData: StageValveTempAndLeakIndexData[] | undefined) {
    const result: ChartingValveLeakIndexData[] = [];

    nestedData?.sort((a, b) => a.stageNumber - b.stageNumber).forEach(stage => {
        stage.throwValveTempAndLeakIndexData.forEach(currentThrow => {
            currentThrow.valveTempAndLeakIndexData.forEach(valve => {
                const leakValveData = valve as ChartingValveLeakIndexData;
                leakValveData.displayName = `Throw ${currentThrow.throwNumber} ${CylinderEnd[valve.cylinderEnd]} ${FlowDirection[valve.flowDirection]} ${valve.valveNumber}`;
                leakValveData.leakSeverity = getValveLeakCategory(valve.leakIndex);
                leakValveData.stageNumber = stage.stageNumber;
                if (valve.leakIndex === -9999) {
                    //@ts-ignore
                    valve.leakIndex = "not received";
                }
                result.push(leakValveData);
            })
        });
    })

    return result;
}

function getValveLeakCategory(leakIndex: number) {
    if (leakIndex < 0) {
        return ValveLeakSeverity.Unknown;
    } else if (leakIndex < 0.5) {
        return ValveLeakSeverity.Normal;
    } else if (leakIndex < 1) {
        return ValveLeakSeverity.Moderate;
    }
    return ValveLeakSeverity.Critical;
}