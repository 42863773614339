import React from 'react';
import {useQuery} from "react-query";
import {Client} from "../../../autogenerate/api.generated.clients";

interface UseVibrationDataProps {
    frameSerialNumber: string
};

function useVibrationData({frameSerialNumber}: UseVibrationDataProps) {
    return useQuery(`vibration-telemetry-${frameSerialNumber}`, async () => new Client().vibrations_GetVibrationData(frameSerialNumber))
}

export default useVibrationData;