import {useQuery} from "react-query";
import {Client, CompressorAlert} from "../../autogenerate/api.generated.clients";
import {AlertResolutionType} from "../../enums";

export interface UseFleetAlertsProps {
    alertResolutionStartDate?: Date | undefined
    alertResolutionEndDate?: Date | undefined,
}

export function useActiveFleetAlerts(enabled: boolean = true) {
    return useQuery<CompressorAlert[], Error>(['active-fleet-alerts'], () => new Client().alert_Get(AlertResolutionType.Active), {staleTime: 300000});
}

export function useResolvedFleetAlerts(props: UseFleetAlertsProps, enabled: boolean = true) {
    return useQuery<CompressorAlert[], Error>(['resolved-fleet-alerts', props], () => new Client().alert_Get(AlertResolutionType.Resolved, props.alertResolutionStartDate?.toUTCString(), props.alertResolutionEndDate?.toUTCString()), {
        enabled, staleTime: 300000
    });
}