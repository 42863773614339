import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {Client, OidcConfig} from "../autogenerate/api.generated.clients";
import {registerLicense} from "@syncfusion/ej2-base";

export interface OidcConfigModel extends OidcConfig {
    onSignIn: (user: any) => void
}

export interface ConfigModel {
    oidcConfig: OidcConfigModel
    isLoading: boolean,
}

const ConfigContext = createContext<ConfigModel>({} as ConfigModel)
export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = (props: { children: React.ReactNode }) => {
    const [config, setConfig] = useState<OidcConfig>({} as OidcConfig);
    const [isLoading, setIsLoading] = useState(true);
    const onSignIn = useCallback(async (user: any) => {
        const desiredUrl = window.localStorage.getItem("pre_sso_url") ?? window.location.origin;
        window.history.pushState({}, "", new URL(desiredUrl));
    }, []);

    useEffect(() => {
        (async function () {
            const result = await new Client().config_Get();
            if (!window.location.href.includes("?code=")) {
                window.localStorage.setItem("pre_sso_url", window.location.href)
            }
            setConfig(result.oidcConfig);
            setIsLoading(false);
            registerLicense(result.syncfusionKey);
        })();
    }, []);

    return (
        <ConfigContext.Provider value={{oidcConfig: {...config, onSignIn}, isLoading: isLoading}}>
            {props.children}
        </ConfigContext.Provider>
    )
}