import {useQuery} from "react-query";
import {Client} from "../../../autogenerate/api.generated.clients";

export interface UseCylinderEndTelemetryPropertiesProps {
    frameSerialNumber: string,
    throwNumber: string,
    cylinderSerialNumber: string
}

export function useCylinderEndTelemetry(props: UseCylinderEndTelemetryPropertiesProps) {
    const {cylinderSerialNumber, throwNumber, frameSerialNumber} = props;
    return useQuery(["cylinder-end-telemetry", cylinderSerialNumber], () => new Client().cylinder_GetCylinderEndTelemetry(cylinderSerialNumber, frameSerialNumber, throwNumber), {staleTime: 300000})
}