import React, {useEffect} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import './compressorsPage.scss';
import {useCompressors} from "../../hooks/FleetDashboard/useCompressors";
import CompressorListView from "../../components/CompressorListView/CompressorListView";
import {CompressorStatusCode} from "../../autogenerate/api.generated.clients";
import ReactGA from "react-ga4";
import {Alert} from "@mui/material";

function CompressorsPage() {
    const compressorsData = useCompressors();
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/compressors", title: "My Fleet" });
    }, []);

    useEffect(() => {
        const hasSerialNumberInUrl = new RegExp(/[F][0-9|A-Z]{1,6}/).test(window.location.href);
        if (compressorsData.data && compressorsData.data.length > 0 && !hasSerialNumberInUrl) {
            const compressor = compressorsData.data.sort((a, b) => a.statusCode - b.statusCode)[0];
            onCompressorSelected(compressor.frameSerialNumber, compressor.statusCode);
        }
    }, [compressorsData.data]);

    const onCompressorSelected = (frameSerialNumber: string, statusCode: CompressorStatusCode) => {
        let route = window.location.pathname;
        if(route == '/compressors' || route == '/') {
            navigate(`/compressors/${frameSerialNumber}/conditions`, {state: {frameSerialNumber, statusCode}});
        }else {
            let routeModified = route.replace(new RegExp(/[F][0-9|A-Z]{1,6}/), frameSerialNumber);
            navigate(routeModified, {state: {frameSerialNumber, statusCode}});
        }
    }
    
    if(compressorsData.data && compressorsData.data.length == 0) {
        return(<Alert severity='error'>No compressors found for user. Please use the "Submit Feedback" button to report an issue.</Alert>)
    }
    

    return (
        <div className='compressors-page'>
            <div className='navigation'>
                <CompressorListView isLoading={compressorsData.isLoading} compressors={compressorsData.data ?? []}
                                    onCompressorSelected={onCompressorSelected}/>
            </div>
            <div className='detail'>
                <Outlet/>
            </div>
        </div>
    );
}

export default CompressorsPage;