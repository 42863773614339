import React from 'react';
import {useTermsAndConditionsStatus} from "../../hooks/TermsAndConditions/useTermsAndConditionsStatus";
import {TermsAndConditionsStatus} from "../../autogenerate/api.generated.clients";
import TermsAndConditionsPage from "../../pages/TermsAndConditionsPage/TermsAndConditionsPage";
import {Dialog} from "@mui/material";

export interface TermsAndConditionsLoaderProps {
    children: React.ReactNode
}

function TermsAndConditionsLoader(props: TermsAndConditionsLoaderProps) {
    const {children} = props;
    const {data, isLoading, refetch} = useTermsAndConditionsStatus();
    const onTermsAccepted = () => {
        refetch();
    };

    if (isLoading || !data || data == TermsAndConditionsStatus.Accepted) {
        return (
            <div>
                {children}
            </div>
        );
    } else {
        return (
            <div>
                {children}
                <Dialog open={true} maxWidth={'xl'} fullWidth>
                    <TermsAndConditionsPage onAccept={onTermsAccepted}/>
                </Dialog>
            </div>
        );
    }

}

export default TermsAndConditionsLoader;