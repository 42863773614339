import React from 'react';
import {useParams} from "react-router-dom";
import useVibrationData from "../../../hooks/Compressor/Vibration/useVibrationData";
import {GridSkeleton} from "../../../components/Skeletons/GridSkeleton";
import {Skeleton, Table, TableCell, TableFooter, TableHead, TableRow, Typography} from "@mui/material";
import './vibrationTab.scss'
import {VibrationOverview} from "../../../autogenerate/api.generated.clients";
import ValueNotReceivedMessage from "../../../components/ValueNotReceivedMessage/ValueNotReceivedMessage";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";

function VibrationTab() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber!;
    const vibrationData = useVibrationData({frameSerialNumber});

    if (vibrationData.isLoading) {
        return <div className={'vibration-tab-content'}>
            <div className={`vibration-table-card e-card flex-sm dashboard-card`}>
                <Typography className={'title'}>Frame vibration</Typography>
                <VibrationTableSkeleton/>
            </div>
            <div className={`vibration-table-card e-card flex-sm dashboard-card`}>
                <Typography className={'title'}>Torsional vibration</Typography>
                <VibrationTableSkeleton/>
            </div>
        </div>;
    }
    return (
        <div className={'vibration-tab-content'}>
            <div className={`vibration-table-card e-card flex-sm dashboard-card`}>
                <Typography className={'title'}>Frame vibration</Typography>
                <ErrorBoundary errorComponent={<ValueNotReceivedMessage/>}>
                    <DerivedPeakTable data={vibrationData.data?.frameOverview}/>
                </ErrorBoundary>
            </div>
            <div className={`vibration-table-card e-card flex-sm dashboard-card`}>
                <Typography className={'title'}>Torsional vibration</Typography>
                <ErrorBoundary errorComponent={<ValueNotReceivedMessage/>}>
                    <DerivedPeakTable data={vibrationData.data?.torsionalOverview}/>
                </ErrorBoundary>
            </div>
        </div>
    );
}

function DerivedPeakTable({data}: { data: VibrationOverview | undefined }) {
    if (!data) {
        return <VibrationTableSkeleton/>
    }
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell align={'left'}></TableCell>
                <TableCell align={'right'}>Value (w/units)</TableCell>
                <TableCell align={'right'}>@RPM</TableCell>
                <TableCell align={'right'}>Ariel Recommended Limit</TableCell>
                <TableCell align={'right'}>Current Set Point</TableCell>
            </TableRow>
        </TableHead>
        <TableRow>
            <TableCell align={'left'}>Derived Peak (last message)</TableCell>
            <TableCell
                align={'right'}>{data.current.value.toFixed(2) ?? "N/A"} {data.current.measurementUnits}</TableCell>
            <TableCell align={'right'}>
                {data.current.atRpm ?
                    `${data.current.atRpm.toFixed(0)}`
                    : "-"}
            </TableCell>
            <TableCell align={'right'}>{data.current.recommendedSetPoint?.toFixed(2) ?? "-"}</TableCell>
            <TableCell align={'right'}>{data.current.currentSetPoint ?? "-"}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Derived Peak 10-Day Average*</TableCell>
            <TableCell
                align={'right'}>{data.average.value.toFixed(2) ?? "N/A"} {data.average.measurementUnits}</TableCell>
            <TableCell align={'right'}>
                {data.average.atRpm ?
                    `${data.average.atRpm.toFixed(0)}`
                    : "-"}
            </TableCell>
            <TableCell align={'right'}>{data.average.recommendedSetPoint?.toFixed(2) ?? "-"}</TableCell>
            <TableCell align={'right'}>{data.average.currentSetPoint ?? "-"}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Derived Peak 10-Day Max*</TableCell>
            <TableCell align={'right'}>{data.max.value.toFixed(2) ?? "N/A"} {data.max.measurementUnits}</TableCell>
            <TableCell align={'right'}>
                {data.max.atRpm ?
                    `${data.max.atRpm.toFixed(0)}`
                    : "-"}
            </TableCell> <TableCell align={'right'}>{data.max.recommendedSetPoint?.toFixed(2) ?? "-"}</TableCell>
            <TableCell align={'right'}>{data.max.currentSetPoint ?? "-"}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Derived Peak 10-Day Min*</TableCell>
            <TableCell align={'right'}>{data.min.value.toFixed(2) ?? "N/A"} {data.min.measurementUnits}</TableCell>
            <TableCell align={'right'}>
                {data.min.atRpm ?
                    `${data.min.atRpm.toFixed(0)}`
                    : "-"}
            </TableCell> <TableCell align={'right'}>{data.min.recommendedSetPoint?.toFixed(2) ?? "-"}</TableCell>
            <TableCell align={'right'}>{data.min.currentSetPoint ?? "-"}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Harmonic Vibration <br/> (Worst case in last message)</TableCell>
            <TableCell
                align={'right'}>{data.worstHarmonic.value.toFixed(2) ?? "N/A"} {data.worstHarmonic.measurementUnits}</TableCell>
            <TableCell align={'right'}>
                {data.worstHarmonic.atRpm ?
                    `${data.worstHarmonic.atRpm.toFixed(0)}`
                    : "-"}
            </TableCell> <TableCell
            align={'right'}>{data.worstHarmonic.recommendedSetPoint?.toFixed(2) ?? "-"}</TableCell>
            <TableCell align={'right'}>{data.worstHarmonic.currentSetPoint ?? "-"}</TableCell>
        </TableRow>
        <TableFooter>
            <Typography className={'footer-text'}>* While running above 300 RPM</Typography>
        </TableFooter>
    </Table>;
}

function VibrationTableSkeleton() {
    return <Table className={'vibration-skeleton'}>
        <TableHead>
            <TableRow>
                <TableCell align={'left'}></TableCell>
                <TableCell align={'right'}>Value (w/units)</TableCell>
                <TableCell align={'right'}>@RPM</TableCell>
                <TableCell align={'right'}>Ariel Recommended Limit</TableCell>
                <TableCell align={'right'}>Current Set Point</TableCell>
            </TableRow>
        </TableHead>
        <TableRow>
            <TableCell align={'left'}>Derived Peak (last message)</TableCell>
            <TableCell align={'right'}>
                <Skeleton width={100} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Derived Peak 10-Day Average*</TableCell>
            <TableCell align={'right'}>
                <Skeleton width={100} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Derived Peak 10-Day Max*</TableCell>
            <TableCell align={'right'}>
                <Skeleton width={100} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Derived Peak 10-Day Min*</TableCell>
            <TableCell align={'right'}>
                <Skeleton width={100} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell align={'left'}>Harmonic Vibration <br/> (Worst case in last message)</TableCell>
            <TableCell
                align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell> 
            <TableCell align={'right'}>
            <Skeleton width={75} height={35}/>
        </TableCell>
            <TableCell align={'right'}>
                <Skeleton width={75} height={35}/>
            </TableCell>
        </TableRow>
        <TableFooter>
            <Typography className={'footer-text'}>* While running above 300 RPM</Typography>
        </TableFooter>
    </Table>;
}


export default VibrationTab;