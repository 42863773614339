import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Inject,
    RecordDoubleClickEventArgs,
    Search,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import React, {useRef, useState} from "react";
import "./AddUserLanding.scss";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import {ButtonComponent} from "@syncfusion/ej2-react-buttons";
import {Link, useParams} from "react-router-dom";
import {useMembersOnlyUsersForOrganization} from "../../../../hooks/Organization/useMembersOnlyUsersForOrganization";
import {MembersOnlyUser} from "../../../../autogenerate/api.generated.clients";

export function AddUserLanding() {
    const {organizationId} = useParams();
    const {data, isLoading} = useMembersOnlyUsersForOrganization(organizationId as string);
    const grid = useRef<GridComponent>(null);
    const toolbarOptions = ['Search'];
    const [isUserSelected, setIsUserSelected] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");

    let tableData: MembersOnlyUser[] | undefined = [];
    if (organizationId !== "undefined" && organizationId !== "null") {
        tableData = data;
    }

    const created = () => {
        document?.getElementById(grid.current?.element.id + "_searchbar")?.addEventListener('keyup', (event) => {
            grid.current?.search((event.target as HTMLInputElement).value)
        });
    }

    const onRecordDoubleClick = async (args: RecordDoubleClickEventArgs) => {
        const rowData = args.rowData as any;
        setSelectedUser(rowData?.email);
        grid.current?.selectRow(args.rowIndex as number);
        setIsUserSelected(true);
    }

    const onCancelUserCreation = () => {
        setSelectedUser("");
        setIsUserSelected(false);
        grid.current?.clearSelection();
    }

    const containerClass = isUserSelected ? "add-user-form" : "add-user-form-single-item";

    return (
        <div className="add-user-landing-container">
            <h2>Add User</h2>
            <hr/>
            <div style={{marginBottom: '1em'}}>
                <ButtonComponent isPrimary><Link to="/administration"><span
                    className="e-icons e-back"/> Back</Link></ButtonComponent>
            </div>
            <div className={containerClass}>
                <div className="available-users-container">
                    <h6>Select a user to add:</h6>
                    <GridComponent id="available-users-to-add" width="100%" height="100%" allowMultiSorting allowSorting
                                   dataSource={tableData}
                                   ref={grid} allowResizing toolbar={toolbarOptions} created={created}
                                   recordDoubleClick={onRecordDoubleClick}
                                   enableStickyHeader>
                        <ColumnsDirective>
                            <ColumnDirective field="email" headerText="Email"/>
                            <ColumnDirective field="firstName" headerText="First Name"/>
                            <ColumnDirective field="lastName" headerText="Last Name"/>
                        </ColumnsDirective>
                        <Inject services={[Toolbar, Search]}/>
                    </GridComponent>
                </div>
                {
                    isUserSelected ?
                        <NewUserForm userName={selectedUser} onCancelUserCreation={onCancelUserCreation}/>
                        :
                        <div/>
                }
            </div>
        </div>
    )
}

interface NewUserFormProps {
    userName: string,
    onCancelUserCreation: Function
}

function NewUserForm(props: NewUserFormProps) {
    const {userName, onCancelUserCreation} = props;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole] = useState('');
    const [orgUnit, setOrgUnit] = useState('');
    const roleDropDown = useRef<DropDownListComponent>(null);
    const orgUnitDropDown = useRef<DropDownListComponent>(null);
    const userRoles: string[] = ["User", "Administrator"];
    const orgUnitOptions: string[] = ["Ariel", "Nevada", "Texas", "Ohio"];

    const onDropDownValueChange = (args: any) => {
        const ddlToChange = args?.target.id;
        const value = args?.nativeEvent.text;

        switch (ddlToChange) {
            case 'user-role':
                setRole(value);
                break;
            case 'org-unit':
                setOrgUnit(value);
                break;
        }
    }

    const onCancelClick = () => {
        if (roleDropDown.current && orgUnitDropDown.current) {
            roleDropDown.current.value = "";
            orgUnitDropDown.current.value = "";
            setRole("");
            setOrgUnit("");
            setPhoneNumber("");
        }
        onCancelUserCreation();
    }

    const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputToUpdate = e.target.id;
        const value = e.target.value;

        switch (inputToUpdate) {
            case 'phone-number':
                setPhoneNumber(value);
                break;
        }
    }

    return (
        <div className="new-user-form-container">
            <div className='new-user-form-fields'>
                <h6>Fill in the user information for user: {userName}</h6>
                <label htmlFor="phone-number">Phone Number</label>
                <input id="phone-number" name="phone-number" type="text" onChange={onInput}/>
                <label htmlFor="user-role">Role</label>
                <DropDownListComponent id="user-role" dataSource={userRoles} onChange={onDropDownValueChange}
                                       ref={roleDropDown}/>
                <label htmlFor="org-unit">Organizational Unit</label>
                <DropDownListComponent id="org-unit" dataSource={orgUnitOptions} onChange={onDropDownValueChange}
                                       ref={orgUnitDropDown}/>
            </div>
            <div className="new-user-form-button-container">
                <ButtonComponent isPrimary={false} id="cancel" className="e-control e-btn e-libg"
                                 onClick={onCancelClick}>Cancel</ButtonComponent>
                <ButtonComponent isPrimary id="submit" className="e-control e-btn e-lib">Submit</ButtonComponent>
            </div>
        </div>
    )
}