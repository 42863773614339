import React, {createContext, useContext, useEffect, useState} from 'react';
import {Client} from "../../autogenerate/api.generated.clients";


const FeatureFlagContext = createContext<string[]>([])
export const useFeatureFlags = () => useContext(FeatureFlagContext);

function FeatureFlagProvider(props: { children: React.ReactNode }) {
    const [featureFlags, setFeatureFlags] = useState<string[]>([]);

    useEffect(() => {
        (async function () {
            try {
                const flags = await new Client().featureFlag_GetFeatureFlags() ?? [];
                setFeatureFlags(flags.map(x=> x.name));
            }
            catch (e)
            {
                setFeatureFlags([]);
            }
        })();
    }, []);
    
    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            {props.children}
        </FeatureFlagContext.Provider>
    );
}

export default FeatureFlagProvider;