import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemText
} from "@mui/material";
import React, {useState} from "react";
import {
    useOrganizationHierarchyForLoggedInUser
} from "../../hooks/Organization/useOrganizationHierarchyForLoggedInUser";
import {HierarchyItem, HierarchyItemType, OrganizationHierarchyItem} from "../../autogenerate/api.generated.clients";
import {useUpdateCompressorEndUser} from "../../hooks/Compressor/useUpdateCompressorEndUser";
import {useQueryClient} from "react-query";

export interface UpdateEndUserDialogProps {
    isOpen: boolean,
    onCloseDialog: Function,
    alertUserAfterEndUserUpdate: Function
    frameSerialNumber: string
}
function UpdateEndUserDialog (props: UpdateEndUserDialogProps) {
    const {isOpen, onCloseDialog, frameSerialNumber, alertUserAfterEndUserUpdate} = props
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [endUserName, setEndUserName] = useState<string | null>(null);
    const [isDisplayMessage, setIsDisplayMessage] = useState(false);
    const orgHierarchies = useOrganizationHierarchyForLoggedInUser();
    const updateCompressorEndUser = useUpdateCompressorEndUser();
    const queryClient = useQueryClient();
    const onCancelClick = () => {
        setSelectedIndex(null);
        setEndUserName(null);
        onCloseDialog();
    }

    const onApplyClick = async () => {
        if(endUserName !== null) {
            await onUpdateEndUser();
        }
    }

    const onUpdateEndUser = async () => {
        const isUpdateSuccessful = await updateCompressorEndUser.updateCompressorEndUser(frameSerialNumber, endUserName!);
        if(isUpdateSuccessful) {
            const message = `Successfully update end user for ${frameSerialNumber} to ${endUserName}.`;
            alertUserAfterEndUserUpdate(true, message, "success");
            await queryClient.refetchQueries({queryKey: [`end-user-for=${frameSerialNumber}`]});
            onCloseDialog();
        }
        else {
            const failureMessage = `There was an error updating end user for ${frameSerialNumber}`;
            alertUserAfterEndUserUpdate(true, failureMessage, "error");
        }
    }

    const toggleDisplayMessage = () => {
        setIsDisplayMessage(!isDisplayMessage);
    }

    const handleListItemClick = (hierarchyItem:HierarchyItem, event:React.MouseEvent<HTMLDivElement, MouseEvent>, index:number) => {
        setSelectedIndex(index);
        setEndUserName(hierarchyItem.name);
    }

    const buildHierarchyList = () => {

        if(orgHierarchies.data) {
            const orgHierarchy = orgHierarchies.data[0] as OrganizationHierarchyItem;
            return (
                <List>
                    {orgHierarchy.children.filter(item => item.type === HierarchyItemType.Org).map((item, index) => {
                        return (
                            <ListItemButton
                                selected={selectedIndex === index}
                                aria-label={`${item.name}`}
                                id={item.id}
                                key={item.id}
                                onClick={e => {handleListItemClick(item, e, index)}}
                            >
                                <ListItemText primary={item.name}/>
                            </ListItemButton>
                        )})}
                </List>
            )
        } else {
            return <div/>
        }
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                Update Frame End User
            </DialogTitle>
            <DialogContent>
                {orgHierarchies.isLoading ?
                        <div>
                            <CircularProgress/>
                        </div>
                    :
                        buildHierarchyList()
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick}>
                    Cancel
                </Button>
                <Button variant="outlined" onClick={onApplyClick}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateEndUserDialog;