import axios from "axios";

axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.transformResponse = [];

export function setCommonAuthHeader(token: string): void {
    axios.defaults.headers["Authorization"] = `Bearer ${token}`;
}

export function setAppkeyHeader(appkey: string): void {
    axios.defaults.headers["appkey"] = appkey;
}
