import React from 'react';
import {ISerialNumberedEquipment} from "../../../autogenerate/api.generated.clients";
import twoThrowFrame from './images/Frame_TwoThrow.png'
import fourThrowFrame from './images/Frame_FourThrow.png'
import sixThrowFrame from './images/Frame_SixThrow.png'
import twoThrowFrameSelected from './images/Frame_TwoThrowSelected.png'
import fourThrowFrameSelected from './images/Frame_FourThrowSelected.png'
import sixThrowFrameSelected from './images/Frame_SixThrowSelected.png'
import './frame.scss';

export interface FrameProps extends React.HTMLProps<HTMLDivElement> {
    equipment: ISerialNumberedEquipment,
    isSelected?: boolean,
    equipmentSelected?: Function
    isDisabled?: boolean
}

export function Frame(props: FrameProps) {
    const {equipment, isSelected, isDisabled} = props;
    const frameData = equipment as any;
    const onClick = () => {
        if (props.equipmentSelected) {
            props.equipmentSelected(equipment);
        }
    }
    return (
        <div className={`frame-container  ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
             style={{...props.style, gridRow: `span ${frameData.throwCount / 2}`}}
             onClick={onClick}>
            <div className="frame-data">
                <div>{equipment.serialNumber}</div>
                <div>{frameData.model}</div>
            </div>
            <img src={getImageForFrame(frameData.throwCount, isSelected)} style={{width: '100%', height: '100%'}}/>
        </div>
    );
}

const getImageForFrame = (throwCount: number, isSelected?: boolean) => {
    switch (throwCount) {
        case 2:
            return isSelected ? twoThrowFrameSelected : twoThrowFrame;
        case 4:
            return isSelected ? fourThrowFrameSelected : fourThrowFrame;
        case 6:
            return isSelected ? sixThrowFrameSelected : sixThrowFrame;
    }
    return "";
}