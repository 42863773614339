import React, {useRef, useState} from 'react';
import {StatusIcon} from "../StatusIcon/StatusIcon";
import {Popover, Typography} from "@mui/material";
import './comopressorAlertTooltip.scss'
import {CompressorAlert, CompressorAlertSeverity, CompressorStatusCode} from "../../autogenerate/api.generated.clients";

interface CompressorAlertTooltipProps {
    alert: CompressorAlert,
    forceTooltip?: boolean
};

function CompressorAlertTooltip(props: CompressorAlertTooltipProps) {
    const {alert, forceTooltip} = props
    const [showTooltip, setShowTooltip] = useState(false);
    const anchorEl = useRef<HTMLDivElement>(null);

    return (
        <div
            ref={anchorEl}
            className={'compressor-alert-tooltip'}
        >
            <div style={forceTooltip || showTooltip ? {cursor: 'pointer', width: '115%', height: '110%'} : {}} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                <StatusIcon
                    statusCode={alert.severity === CompressorAlertSeverity.Shutdown ? CompressorStatusCode.ShutdownFault : CompressorStatusCode.ActiveWarning}/>
            </div>
            <Popover open={showTooltip || (forceTooltip ?? false)}
                     style={{pointerEvents: 'none'}}
                     slotProps={{paper: {className: 'compressor-alert-tooltip-popover-content'}}}
                     onClose={() => setShowTooltip(false)}
                     transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                     anchorEl={anchorEl.current}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}>
                <Typography fontWeight={'700'} align={'center'} fontSize={'.8em'} paddingBottom={'.5em'}>
                    {new Date(alert.faultTimeStamp).toLocaleString()}
                </Typography>
                <div className={'compressor-alert-tooltip-description'}>
                    <div>
                        <StatusIcon
                            statusCode={alert.severity === CompressorAlertSeverity.Shutdown ? CompressorStatusCode.ShutdownFault : CompressorStatusCode.ActiveWarning}/>
                    </div>
                    <Typography flex={1}>
                        {alert.faultDescription}
                    </Typography>
                </div>
            </Popover>
        </div>
    )
}

export default CompressorAlertTooltip;