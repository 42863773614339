import {Client, TermsAndConditionsStatus} from "../../autogenerate/api.generated.clients";

export function useUpdateTermsAndConditionsStatus() {
    const updateTermsAndConditionsStatus = async (status: TermsAndConditionsStatus) => {
        //ts ignore is required here after .net8 upgrade because for some reason the autogenerated controller functions think they expect a string where Enum is required
        //this is not an issue in earlier version of .net
        //@ts-ignore
        await new Client().termsAndConditions_UpdateTermsAndConditionsStatus(status);
    }

    return {updateTermsAndConditionsStatus};
}