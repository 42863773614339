import {useQuery} from "react-query";
import {Client} from "../../autogenerate/api.generated.clients";

export interface UseOrganizationHierarchyProps {
    orgId: number
}

export function useOrganizationHierarchyForOrg(props: UseOrganizationHierarchyProps) {
    const {orgId} = props;
    return useQuery(['organizations-hierarchy', orgId], () => new Client().organization_GetOrganizationHierarchyForOrg(orgId), {
        staleTime: Infinity,
        enabled: orgId > 0
    });
}