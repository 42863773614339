import React, {useEffect, useState} from 'react';
import {
    UpdateNotification,
    useNotificationRulesForCompressor
} from "../../../hooks/Notifications/useNotificationRulesForCompressor";
import './notificationRulesTabContent.scss'
import {DefaultNotificationDTO} from "../../../autogenerate/api.generated.clients";
import {useAuth} from "oidc-react";
import {
    Button,
    Dialog,
    DialogContent,
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import AlertUser from "../../../components/AlertUser/AlertUser";
import useNotificationPreferences from "../../../hooks/NotificationPreferences/useNotificationPreferences";
import PhoneNumberValidationView from "../../../components/PhoneNumberValidationView/PhoneNumberValidationView";
import Edit from "@mui/icons-material/Edit";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";

export default function NotificationRulesTabContent() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber!;
    const {userData} = useAuth();
    const  {data: notificationPreferences, refetch} = useNotificationPreferences();
    const [{data}, mutation, alertInfo] = useNotificationRulesForCompressor({
        frameSerialNumber,
        userEmail: userData?.profile.email!
    });
    const [isPhoneDialogOpen, setIsPhoneDialogOpen] = useState(false);
    const [isPhoneAlertOpen, setIsPhoneAlertOpen] = useState(false);
    const [isUserPhoneNumberPresent, setIsUserPhoneNumberPresent] = useState(!!notificationPreferences?.phoneNumber);
    const [isNoPhoneNumberAlert, setIsNoPhoneNumberAlert] = useState(false);
    
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/compressors/${frameSerialNumber}/notifications`, title: "Compressor Notifications" });
    }, []);

    const updateNotification: (notificationToUpdate: DefaultNotificationDTO) => UpdateNotification = notificationToUpdate => valuesToUpdate => {
        mutation.mutate({notificationToUpdate, ...valuesToUpdate});
    }

    const handleClosePhoneNumberDialog= () => {
        setIsPhoneDialogOpen(false);
    }

    const onSmsToggled = () => {
        if (!notificationPreferences?.phoneNumber) {
            setIsPhoneDialogOpen(true)
        }
    }

    const toggleNoPhoneNumberAlert = () => {
        setIsNoPhoneNumberAlert(!isNoPhoneNumberAlert);
    }
    const onPhoneNumberUpdated = () => {
        setIsUserPhoneNumberPresent(true);
        setIsPhoneDialogOpen(false);
        setIsPhoneAlertOpen(true);
        refetch();
    }

    return (
        <div className="compressor-notification-rules">
            <div className='notification-preferences'>
                <div>
                    <Typography>
                        <span className='bold'>Email: </span>
                        {notificationPreferences?.email ?
                            notificationPreferences?.email : "NA"}
                    </Typography>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography>
                        <span className='bold'>Phone: </span>
                        {notificationPreferences?.phoneNumber ?
                            notificationPreferences?.phoneNumber : "NA"}
                    </Typography>
                    <IconButton
                        onClick={(e) => setIsPhoneDialogOpen(true)}>
                        <Edit/>
                    </IconButton>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell align="center">Enable Email?</TableCell>
                            <TableCell align="center">Enable SMS?</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {data?.map(x => (
                            <NotificationFormRow key={x.id} isEmailEnabled={x.isEmailEnabled}
                                                 isSmsEnabled={x.isSmsEnabled}
                                                 description={x.description}
                                                 updateNotification={updateNotification(x)}
                                                 onSmsToggled={onSmsToggled}
                                                 isUserPhoneNumberPresent={isUserPhoneNumberPresent}
                                                 toggleNoPhoneNumberAlert={toggleNoPhoneNumberAlert}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AlertUser {...alertInfo} />
            <AlertUser open={isPhoneAlertOpen} message={"Successfully updated phone number"} severity={'success'}
                       resetAlertState={() => setIsPhoneAlertOpen(false)}/>
            <AlertUser open={isNoPhoneNumberAlert} message={"Please enter a phone number to subscribe to SMS notifications."}
                       severity={'error'} resetAlertState={() => setIsNoPhoneNumberAlert(false)} />
            <Dialog open={isPhoneDialogOpen}>
                <DialogContent>
                    <PhoneNumberValidationView 
                        onSuccess={onPhoneNumberUpdated}
                        onInputCancel={handleClosePhoneNumberDialog}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
}

const NotificationFormRow = (props: {
    updateNotification: UpdateNotification,
    isEmailEnabled: boolean,
    isSmsEnabled: boolean,
    description: string,
    onSmsToggled?: Function,
    isUserPhoneNumberPresent: boolean,
    toggleNoPhoneNumberAlert: Function
},) => {
    const {updateNotification, isEmailEnabled, isSmsEnabled, description} = props;
    const [checked, setChecked] = useState({isEmailEnabled, isSmsEnabled});

    useEffect(() => {
        setChecked({isEmailEnabled, isSmsEnabled});
    }, [isEmailEnabled, isSmsEnabled])

    useEffect(() => {
        if (props.onSmsToggled && checked.isSmsEnabled) {
            props.onSmsToggled();
        }
    }, [checked.isSmsEnabled]);

    const handleChange = (checkbox: 'isEmailEnabled' | 'isSmsEnabled') => (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(state => ({
            ...state,
            [checkbox]: event.target.checked
        }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if(!props.isUserPhoneNumberPresent && checked.isSmsEnabled) {
            props.toggleNoPhoneNumberAlert();
        } 
        else {
            updateNotification({
                isEmailEnabled: checked.isEmailEnabled,
                isSmsEnabled: checked.isSmsEnabled
            });
        }
        

    }

    return (
        <TableRow>
            <TableCell component="th" scope="row">
                <Typography variant={"h6"}>{description}</Typography>
            </TableCell>
            <TableCell align='center'>
                <Switch inputProps={{name: 'isEmailEnabled', "aria-label": 'Enable Email'}}
                        checked={checked.isEmailEnabled}
                        onChange={handleChange('isEmailEnabled')}/>
            </TableCell>
            <TableCell align='center'>
                <Switch inputProps={{name: 'isSmsEnabled', "aria-label": 'Enable SMS'}} checked={checked.isSmsEnabled}
                        onChange={handleChange('isSmsEnabled')}/>
            </TableCell>
            <TableCell align='center'>
                {(checked.isSmsEnabled !== isSmsEnabled || checked.isEmailEnabled !== isEmailEnabled)
                    ? <Button
                        data-testid="submitNotification"
                        onClick={handleSubmit}
                    >Submit</Button>
                    : <Button
                        sx={{visibility: 'hidden'}}
                        onClick={handleSubmit}
                    >Submit</Button>
                }
            </TableCell>
        </TableRow>
    );
}
