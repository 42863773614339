import React, {useEffect, useState} from "react";
import {
    AlertColor,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Popover,
    TextField,
    Typography
} from "@mui/material";
import {StatusIcon} from "../StatusIcon/StatusIcon";
import {Compressor} from "../../autogenerate/api.generated.clients";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import './CompressorListViewItem.scss'
import {useUpdateEndUserUnitId} from "../../hooks/FleetDashboard/useCompressors";
import AlertUser from "../AlertUser/AlertUser";
import CloseIcon from "@mui/icons-material/Close";
import UpdateEndUserDialog from "../UpdateEndUserDialog/UpdateEndUserDialog";
import {useGetIsArielEmployee} from "../../hooks/Organization/useGetIsArielEmployee";

export interface CompressorListViewItemProps extends React.ComponentProps<any> {
    key: string
    compressor: Compressor,
    onCompressorClick: Function,
    onFavoriteIconClicked: Function
}

function CompressorListViewItem(props: CompressorListViewItemProps) {
    const {compressor, onCompressorClick, onFavoriteIconClicked} = props;
    const [actualEndUserUnitId, setActualEndUserUnitId] = useState(compressor.endUserUnitId);
    const [tempEndUserUnitId, setTempEndUserUnitId] = useState(compressor.endUserUnitId);
    const [filterActionsEl, setActionsAnchorEl] = useState<HTMLElement | null>(null);
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const [isEditEndUserUnitIdOpen, setIsEditEndUserUnitIdOpen] = useState(false);
    const [isDisplayMessage, setIsDisplayMessage] = useState(false);
    const [userMessage, setUserMessage] = useState("");
    const [messageSeverity, setMessageSeverity] = useState<AlertColor>("success");
    const [isUpdateEndUserOpen, setIsUpdateEndUserOpen] = useState(false);
    const update = useUpdateEndUserUnitId();
    const isArielEmployee = useGetIsArielEmployee();

    useEffect(() => {
        setTempEndUserUnitId(compressor.endUserUnitId);
        setActualEndUserUnitId(compressor.endUserUnitId);
    }, [compressor]);

    const onActionMenuClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionsAnchorEl(event.currentTarget)
        setIsActionsOpen(true);
    };

    const onActionMenuClosed = () => {
        setActionsAnchorEl(null);
        setIsActionsOpen(false);
    }

    const onEditEndUserClicked = () => {
        setIsEditEndUserUnitIdOpen(true);
    }

    const onUpdateEndUserClicked = () => {
        setIsUpdateEndUserOpen(!isUpdateEndUserOpen);
    }

    const onEditEndUserClosed = () => {
        setIsEditEndUserUnitIdOpen(false);
    }

    const onEditEndUserTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTempEndUserUnitId(event.target.value)
    };

    const onEditEndUseApply = async (frameId: string, tempEndUserUnitId: string) => {
        let isUpdateSuccessful = false;
        try {
            isUpdateSuccessful = await update.updateEndUserUnitId(frameId, tempEndUserUnitId);
        } catch {
        }
        if (!isUpdateSuccessful) {
            setUserMessage(`Unable to update end user unit id for frame: ${frameId}`);
            setMessageSeverity("error");
        } else {
            setUserMessage(`Successfully updated end user unit id for frame: ${frameId}`);
            setMessageSeverity("success");
            setActualEndUserUnitId(tempEndUserUnitId);
        }
        setIsEditEndUserUnitIdOpen(false);
        setIsActionsOpen(false);
        setIsDisplayMessage(true);
    }

    const resetAlertState = () => {
        setIsDisplayMessage(false);
        setUserMessage("");
        setMessageSeverity("success");
    }

    const formatFaultCategories = (compressor: Compressor) => {
        return compressor.faultCategories.split(/(?=[A-Z])/).map(s => s.trim()).join(" ");
    }

    const alertUserAfterEndUserUpdate = (isDisplayAlert: boolean, message: string, severity: AlertColor) => {
        setIsDisplayMessage(isDisplayAlert);
        setUserMessage(message);
        setMessageSeverity(severity);
    }

    return (
        <div>
            <Popover open={isActionsOpen}
                     onClose={onActionMenuClosed}
                     transformOrigin={{vertical: 0, horizontal: -20}}
                     anchorEl={filterActionsEl}
                     anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'right',
                     }}>
                <List aria-labelledby="nested-list-subheader"
                      subheader={
                          <div className="header-container">
                              <ListSubheader component="div" id="nested-list-subheader">
                                  Actions
                              </ListSubheader>
                              <div className="close-button">
                                  <IconButton aria-label="close filters" size="small" onClick={onActionMenuClosed}>
                                      <CloseIcon fontSize="inherit"/>
                                  </IconButton>
                              </div>
                          </div>
                      }>
                    <ListItemButton aria-label={`${compressor.frameSerialNumber} open edit`}
                                    onClick={onEditEndUserClicked}>
                        <ListItemIcon sx={{minWidth: '40px'}}>
                            <EditIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Edit end user unit id"/>
                    </ListItemButton>
                    {isArielEmployee.data ?
                        <ListItemButton aria-label={`${compressor.frameSerialNumber} update enduser`}
                                        onClick={onUpdateEndUserClicked}>
                            <ListItemIcon sx={{minWidth: '40px'}}>
                                <SyncAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Update equipment end user"/>
                        </ListItemButton>
                        :
                        ''
                    }
                </List>
            </Popover>
            <Dialog open={isEditEndUserUnitIdOpen}>
                <DialogTitle>
                    Edit end user unit id
                </DialogTitle>
                <DialogContent>
                    <TextField size='small'
                               defaultValue={tempEndUserUnitId}
                               InputProps={{type: 'text'}}
                               onChange={onEditEndUserTextChanged}
                               sx={{minWidth: '300px'}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={onEditEndUserClosed}
                            size="small"
                    >
                        Cancel
                    </Button>
                    <Button variant="outlined"
                            aria-label={`${compressor.frameSerialNumber} apply edit`}
                            onClick={() => onEditEndUseApply(compressor.frameSerialNumber, tempEndUserUnitId)}
                            size="small"
                    >
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
            <UpdateEndUserDialog
                isOpen={isUpdateEndUserOpen}
                onCloseDialog={onUpdateEndUserClicked}
                frameSerialNumber={compressor.frameSerialNumber}
                alertUserAfterEndUserUpdate={alertUserAfterEndUserUpdate}
            />
            <AlertUser open={isDisplayMessage} severity={messageSeverity} message={userMessage}
                       resetAlertState={resetAlertState}/>
            <ListItemButton alignItems="center" divider sx={{padding: '.25em 1em', width: '100%'}}
                            className='compressor-list-item'
                            onClick={() => {
                                onCompressorClick(compressor.frameSerialNumber, compressor.statusCode);
                            }}
                            selected={window.location.pathname.includes(compressor.frameSerialNumber)}>
                <div className='compressor-status-icon'>
                    <StatusIcon statusCode={compressor.statusCode} isLoading={false}/>
                </div>
                <ListItemText primary={
                    <Typography noWrap={true} fontSize='large' fontWeight={500}>
                        {actualEndUserUnitId.trim() != ""
                            ? actualEndUserUnitId
                            : compressor.frameSerialNumber
                        }
                    </Typography>
                }
                              secondary={
                                  <span className='secondary-container'>
                        <Typography sx={{fontSize: '.75rem'}}
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                        >
                            {compressor.frameSerialNumber + ((compressor.model && compressor.model !== '') ? ` - ${compressor.model}` : '')}
                        </Typography>
                        <Typography sx={{fontSize: '.75rem'}}
                                    component="span"
                                    variant="caption"
                                    color="#af3219"
                        >
                            {formatFaultCategories(compressor)}
                        </Typography>
                    </span>
                              }
                />
                <IconButton aria-label={`${compressor.frameSerialNumber} toggle favorite`}
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                                e.stopPropagation();
                                onFavoriteIconClicked(compressor);
                            }}>
                    {compressor.isFavorite
                        ? <Star color='primary'/>
                        : <StarBorder color='disabled'/>
                    }
                </IconButton>
                <IconButton aria-label={`${compressor.frameSerialNumber} action menu`}
                            onMouseDown={(e) => e.stopPropagation()}
                            onClick={(e) => {
                                e.stopPropagation();
                                onActionMenuClicked(e);
                            }}>
                    <MoreVertIcon color='action'/>
                </IconButton>
            </ListItemButton>
        </div>
    );
}

export default CompressorListViewItem;