import React, {useEffect, useReducer, useState} from 'react';
import {
    Compressor,
    CompressorStatusCode,
    HierarchyItemType,
    OrganizationHierarchyItem
} from "../../autogenerate/api.generated.clients";
import {Backdrop, Badge, Button, List, ListItemButton, Popover, TextField, Typography} from "@mui/material";
import './compressorListView.scss';
import ListItemSkeleton from "../Skeletons/ListItemSkeleton";
import {useToggleIsFavorite} from "../../hooks/Favorites/useToggleIsFavorite";
import FilterAlt from "@mui/icons-material/FilterAlt";
import CompressorFilterView from "../CompressorFilterView/CompressorFilterView";
import CompressorListViewItem from "../CompressorListViewItem/CompressorListViewItem";

export interface CompressorListViewProps extends React.ComponentProps<any> {
    isLoading: boolean,
    compressors: Compressor[],
    onCompressorSelected: Function
}

function CompressorListView(props: CompressorListViewProps) {
    const [searchText, setSearchText] = useState<string | null>();
    const {compressors, isLoading} = props;
    const {toggleIsFavorite} = useToggleIsFavorite();
    const [filterAnchorEl, setFilterAnchorEl] = React.useState<HTMLElement | null>(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [hasFavoritesFiltered, setHasFavoritesFiltered] = useState(false);
    const [selectedStatusCodes, setSelectedStatusCodes] = useState([] as CompressorStatusCode[]);
    const [selectedFrameSerialNumbers, setSelectedFrameSerialNumbers] = useState([] as string []);
    const [selectedSuborgs, setSelectedSuborgs] = useState([] as OrganizationHierarchyItem[]);
    const [filterCount, setFilterCount] = useState(0);

    useEffect(() => {
        const frameSerialNumbers = selectedSuborgs.flatMap(s => s.children.filter(c => c.type === HierarchyItemType.Frame).map(c => c.name));
        setSelectedFrameSerialNumbers(frameSerialNumbers);
    }, [selectedSuborgs]);
    useEffect(() => {
        let count = 0;
        if (hasFavoritesFiltered)
            count++;
        if (selectedSuborgs.length > 0)
            count++;
        if (selectedStatusCodes.length > 0)
            count++;
        setFilterCount(count);
    }, [hasFavoritesFiltered, selectedSuborgs, selectedStatusCodes]);
    const onCompressorClick = (frameSn: string, status: CompressorStatusCode) => {
        props.onCompressorSelected(frameSn, status);
    }

    const onSearchTextChanged = (event: any) => {
        setSearchText(event.target.value);
    };

    const onFavoriteIconClicked = (compressor: Compressor) => {
        toggleIsFavorite(compressor)
        forceUpdate();
    }

    const onFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterAnchorEl(event.currentTarget)
        setIsFilterOpen(!isFilterOpen);
    }

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
        setIsFilterOpen(false);
    }

    const onCloseFiltersClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        handleFilterClose();
    }

    const onClearFiltersClick = () => {
        setHasFavoritesFiltered(false);
        setSelectedStatusCodes([] as CompressorStatusCode[]);
        setSelectedSuborgs([] as OrganizationHierarchyItem[]);
    }

    return (
        <div className='compressor-list'>
            <div className='search-and-filter'>
                <div className='search-bar-container'>
                    <Typography variant='h6'>
                        My Fleet
                    </Typography>
                    <Button className='filter-button'
                            onClick={onFilterBtnClick}>
                        <Badge
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            badgeContent={filterCount}
                            color={"error"}>
                            <FilterAlt fontSize='small'/>
                        </Badge>
                        Filter
                    </Button>
                </div>
                <Backdrop
                    sx={{color: '#fff'}}
                    open={isFilterOpen}
                    onClick={handleFilterClose}
                />
                <Popover open={isFilterOpen}
                         onClose={() => setIsFilterOpen(false)}
                         PaperProps={{sx: {maxHeight: '90%'}}}
                         transformOrigin={{vertical: 0, horizontal: -20}}
                         anchorEl={filterAnchorEl}
                         anchorOrigin={{
                             vertical: 'top',
                             horizontal: 'right',
                         }}>
                    <CompressorFilterView
                        initialIsFavoriteSelected={hasFavoritesFiltered}
                        initialSelectedStatuses={selectedStatusCodes}
                        initialSubOrgSelection={selectedSuborgs}
                        onFavoriteFilterChanged={setHasFavoritesFiltered}
                        onSelectedStatusCodeChanged={setSelectedStatusCodes}
                        onSelectedSubOrgsChanged={setSelectedSuborgs}
                        filterCount={filterCount}
                        onCloseFiltersClick={onCloseFiltersClick}
                        onClearFiltersClick={onClearFiltersClick}
                    />
                </Popover>
                <TextField
                    size='small'
                    className='search-input'
                    label="Search compressors"
                    onChange={onSearchTextChanged}
                    InputProps={{
                        type: 'search',
                    }}
                />
            </div>
            <List>
                {
                    isLoading ?
                        Array(10).fill({}).map((item, index) =>
                            <ListItemButton key={index}
                                            alignItems="center" divider sx={{padding: '.25em 1em', width: '100%'}}
                                            className='compressor-list-item'>
                                <ListItemSkeleton/>
                            </ListItemButton>
                        )

                        :

                        compressors
                            .sort((a, b) => getStatusCodeSort(a.statusCode) - getStatusCodeSort(b.statusCode))
                            .filter(c => !searchText || 
                                c.endUserUnitId.toLowerCase().includes(searchText.toLowerCase()) || 
                                c.frameSerialNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                                c.model.toLowerCase().includes(searchText.toLowerCase()))
                            .filter(c => !hasFavoritesFiltered || c.isFavorite)
                            .filter(c => selectedStatusCodes.length === 0 || selectedStatusCodes.includes(c.statusCode))
                            .filter(c => selectedFrameSerialNumbers.length === 0 || selectedFrameSerialNumbers.includes(c.frameSerialNumber))
                            .map((compressor) =>
                                <CompressorListViewItem key={compressor.frameSerialNumber} compressor={compressor}
                                                        onCompressorClick={onCompressorClick}
                                                        onFavoriteIconClicked={onFavoriteIconClicked}/>
                            )}
            </List>
        </div>
    );
}

function getStatusCodeSort(statusCode: CompressorStatusCode) {
    switch(statusCode) {
        case CompressorStatusCode.ShutdownFault:
            return 0
        case CompressorStatusCode.RunningShutdownFault:
            return 1
        case CompressorStatusCode.ActiveWarning:
            return 2
        case CompressorStatusCode.OK:
            return 3
        case CompressorStatusCode.NonASC:
            return 4
        case CompressorStatusCode.NormalShutdown:
            return 5
        case CompressorStatusCode.Disconnected:
            return 6
        case CompressorStatusCode.Unknown:
            return 7
    }
}
export default CompressorListView;