import React from 'react';
import './organizationManager.scss';
import {HierarchyItem, HierarchyItemType, OrganizationHierarchyItem} from "../../../autogenerate/api.generated.clients";
import {ListItemText, Menu, MenuItem, MenuList} from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import Business from '@mui/icons-material/Business';
import AddBusiness from '@mui/icons-material/AddBusiness';
import Delete from '@mui/icons-material/Delete';

export interface OrganizationTreeNodeProps {
    data: HierarchyItem
    onDelete: Function
    onRename: Function,
    onAddOrg: Function,
    onAddCompressors: Function,
    onDeleteCompressors: Function
}

export function OrganizationTreeNode(props: OrganizationTreeNodeProps) {
    let {data} = props;
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchorEl);
    const isOrganization = data.type == HierarchyItemType.Org;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setMenuAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setMenuAnchorEl(null);
    };

    const handleDeleteClicked = () => {
        props.onDelete(data);
    }

    const handleRenameClicked = () => {
        props.onRename(data as OrganizationHierarchyItem);
    }

    const handleAddClicked = () => {
        props.onAddOrg(data as OrganizationHierarchyItem);
    }

    const handleAddCompressorsClicked = () => {
        props.onAddCompressors();
    }

    const handleDeleteCompressorClick = () => {
        props.onDeleteCompressors();
    }

    return (
        <div>
            <div className="tree-node" onContextMenu={handleClick}>
                {
                    isOrganization ?
                        <Business fontSize="medium"/>
                        : ''
                }
                <div className={data.type.toString().toLowerCase()}>{data.name}</div>
            </div>
            {
                isOrganization ?
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuList>
                            <MenuItem onClick={handleAddCompressorsClicked}>
                                <ListItemIcon>
                                    <AddBusiness fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Add Frame</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handleDeleteCompressorClick}>
                                <ListItemIcon>
                                    <Delete fontSize={"small"} />
                                </ListItemIcon>
                                <ListItemText>Remove Frame</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    : ''
            }
        </div>
    );
}