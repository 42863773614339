import React, {useRef} from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    GridComponent,
    Inject,
    Sort,
    Toolbar,
    ToolbarItems
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import {GridSkeleton} from "../../../../components/Skeletons/GridSkeleton";
import {useThrowRodLoadTelemetry} from "../../../../hooks/Compressor/Telemetry/useThrowRodLoadTelemetry";

export interface FrameTelemetryProps extends React.HTMLProps<HTMLDivElement> {
    frameSerialNumber: string,
    throwNumber: string
}

export function ThrowRodLoadTelemetry(props: FrameTelemetryProps) {
    const {frameSerialNumber, throwNumber, ...reactProps} = props;
    const {data, isLoading, error} = useThrowRodLoadTelemetry(frameSerialNumber, throwNumber);
    const grid = useRef<GridComponent>(null);
    const toolbarOptions: ToolbarItems[] = ['Search', 'ExcelExport'];
    const toolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.excelExport({fileName: `${frameSerialNumber}_RodLoadTelemetry.xlsx`});
        }
    };
    const created = () => {
        document?.getElementById(grid.current?.element.id + "_searchbar")?.addEventListener('keyup', (event) => {
            grid.current?.search((event.target as HTMLInputElement).value)
        });
    };
    if (isLoading) {
        return <div className={`e-card ${reactProps.className}`}>
            <GridSkeleton columns={2} rows={4}/>
        </div>
    }
    return (
        <div {...reactProps} className={`e-card throw-rod-load-telemetry ${reactProps.className}`}>
            <span className="e-card-header">Throw {props.throwNumber} Rod load & Reversal</span>
            <GridComponent gridLines="Both" dataSource={data} allowReordering toolbarClick={toolbarClick} ref={grid}
                           id="throw-rod-load-telemetry" allowMultiSorting allowSorting
                           toolbar={toolbarOptions} allowExcelExport created={created}>
                <ColumnsDirective>
                    <ColumnDirective headerText="Property" field="displayName"/>
                    <ColumnDirective headerText="Value" field="value"/>
                </ColumnsDirective>
                <Inject services={[Toolbar, Sort, ExcelExport]}/>
            </GridComponent>
        </div>
    );
}