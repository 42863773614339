import React, {useEffect, useRef} from 'react';
import './valveData.scss';
import {useValveConditions} from "../../../hooks/Compressor/useValveConditions";
import {ChartSkeleton} from "../../../components/Skeletons/ChartSkeleton";
import {TabComponent,} from "@syncfusion/ej2-react-navigations";
import {ValveTempData} from "./ValveTempData/ValveTempData";
import {ValveLeakData} from "./ValveLeakData/ValveLeakData";
import {GridSkeleton} from "../../../components/Skeletons/GridSkeleton";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";

export default function ValveData() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber;
    const {data, isLoading, error} = useValveConditions(frameSerialNumber!);
    const tabComponent = useRef<TabComponent>(null);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/compressors/${frameSerialNumber}/valves`, title: "Compressor Valves" });
    }, []);

    if (isLoading) {
        return (<div className="valve-tab-content">
            <div className="e-card" style={{width: '25vw'}}>
                <GridSkeleton columns={4} rows={12}/>
            </div>
            <div className="e-card" style={{height: "60vh", width: '50vw'}}>
                <ChartSkeleton/>
            </div>
        </div>)
    }

    const goToLeaksTab = () => {
        tabComponent.current?.select(1);
    }
    return (
        <div className="valve-tab-content">
            <ValveLeakData stages={data}/>
            <ValveTempData stages={data} onValveLabelClicked={goToLeaksTab}/>
        </div>
    );
}
