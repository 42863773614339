import React, {useEffect, useState} from 'react';
import {ChartingVariable, CompressorAlert, CompressorAlertSeverity} from "../../../autogenerate/api.generated.clients";
import {DropDownListComponent, SelectEventArgs} from "@syncfusion/ej2-react-dropdowns";
import './chartsTab.scss';
import {ChangeEventArgs, SwitchComponent} from "@syncfusion/ej2-react-buttons";
import {getDaysAgo, getHoursAgo, getOneDayAgo, minuteDifference} from "../../../utilities/dateHelper";
import {ChangedEventArgs, DateTimePickerComponent} from "@syncfusion/ej2-react-calendars";
import {useChartingData} from "../../../hooks/Compressor/Reports/Charting/useChartingData";
import {ChartVariableGrid} from "./ChartVariables/ChartVariableGrid";
import {ChartSkeleton} from "../../../components/Skeletons/ChartSkeleton";
import {DataExport} from "../../../components/DataExport/DataExport";
import {LineChartMultiYAxis} from "../../../components/Charting/LineChart/LineChart";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";
import SettingsIcon from '@mui/icons-material/Settings';
import {IconButton} from "@mui/material";
import {useChartingFaults} from "../../../hooks/Compressor/Reports/Charting/useChartingFaults";
import ChartSettingsMenu from "../../../components/Charting/ChartSettingsMenu/ChartSettingsMenu";
import {useIsMultiChartAxisEnabled} from "../../../hooks/FeatureFlags/useIsMultiChartAxisEnabled";

export default function ChartsTab() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber!;
    const [selectedDuration, setSelectedDuration] = useState('24 Hours');
    const [isShowingDigitalTwin, setIsShowingDigitalTwin] = useState(false);
    const [startDate, setStartDate] = useState<Date>(getOneDayAgo());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [selectedVariables, setSelectedVariables] = useState<ChartingVariable[]>([]);
    const [dataForExport, setDataForExport] = useState<ChartingVariable[]>([]);
    const [reportTitle, setReportTitle] = useState("");
    const [leftSeries, setLeftSeries] = useState<ChartingVariable[]>([]);
    const [rightSeries, setRightSeries] = useState<ChartingVariable[]>([]);
    const [selectedOverlayAlerts, setSelectedOverlayAlerts] = useState<CompressorAlert[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isOverlayShutdowns, setIsOverlayShutdowns] = useState(false);
    const [isOverlayWarnings, setIsOverlayWarnings] = useState(false);
    const alertsData = useChartingFaults(frameSerialNumber, startDate, endDate);
    const isMultiAxisEnabled = useIsMultiChartAxisEnabled();

    const variablesWithData = useChartingData({
        params: {
            frameSerialNumber,
            startDate: startDate.toUTCString(),
            endDate: endDate.toUTCString(),
            variables: selectedVariables
        }
    })
    const durationOptions: string[] = ["1 Hour", "12 Hours", "24 Hours", "7 Days", "30 Days", "Custom"];

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/compressors/${frameSerialNumber}/charts`,
            title: "Compressor Charting"
        });
    }, []);

    useEffect(() => {
        setDataForExport(variablesWithData.data ?? []);
        setReportTitle(`${frameSerialNumber} Chart Data ${new Date().toLocaleDateString('en-US', {
            hour12: true,
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        })}`);
        if( !isMultiAxisEnabled && variablesWithData.data?.length == 2)
        {
            setLeftSeries([variablesWithData.data[0]] ?? []);
            setRightSeries([variablesWithData.data[1]] ?? []);
        }
        else {
            setLeftSeries(variablesWithData.data ?? []);
            setRightSeries([]);
        }
    }, [variablesWithData.data, isShowingDigitalTwin]);

    const onToggleDigitalTwin = (args: ChangeEventArgs) => {
        setIsShowingDigitalTwin(args.checked ?? false);
    }

    const onStartDateChanged = (args: ChangedEventArgs) => {
        if (args.value && minuteDifference(args.value, startDate) >= 30) {
            setStartDate(args.value)
        }
    }
    const onEndDateChanged = (args: ChangedEventArgs) => {
        if (args.value && minuteDifference(args.value, endDate) >= 30) {
            setEndDate(args.value)
        }
    }
    const onDurationSelected = (args: SelectEventArgs) => {
        var newValue = args.itemData.value;
        if (newValue != selectedDuration) {
            setSelectedDuration(args.itemData.value ?? "");
            switch (newValue) {
                case "1 Hour":
                    setStartDate(getHoursAgo(1));
                    setEndDate(new Date());
                    break;
                case "12 Hours":
                    setStartDate(getHoursAgo(12));
                    setEndDate(new Date());
                    break;
                case "24 Hours":
                    setStartDate(getOneDayAgo());
                    setEndDate(new Date());
                    break;
                case "7 Days":
                    setStartDate(getDaysAgo(7))
                    setEndDate(new Date());
                    break;
                case "30 Days":
                    setStartDate(getDaysAgo(30))
                    setEndDate(new Date());
                    break;
            }
        }
    }
    const onPlot = (variables: ChartingVariable[]) => {
        setSelectedVariables(variables);
        variables.forEach(variable =>
            ReactGA.event("variable charted", {
                variable_name: variable.ascPropertyName,
                time_range_hours: (endDate.getTime() - startDate.getTime()) / 3600000.0,
                frame_serial_number: frameSerialNumber
            })
        );
    }

    const handleIconMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const onPlotAlerts = (selectedAlerts: CompressorAlert[]) => {
        setSelectedOverlayAlerts(selectedAlerts);
        setAnchorEl(null);
    }

    const alertsToOverlay = alertsData.data?.filter(a=> {
        return (a.severity === CompressorAlertSeverity.Shutdown && isOverlayShutdowns)
            || (a.severity === CompressorAlertSeverity.Warning && isOverlayWarnings)
            || (selectedOverlayAlerts.some(b=> a === b))
    })
        .sort((a, b) => new Date(a.faultTimeStamp).getTime() - new Date(b.faultTimeStamp).getTime()) ?? [];
    return (
        <div className="compressor-landing-chart-tab">
            <ChartVariableGrid onSubmit={onPlot} frameId={frameSerialNumber}/>
            <div className="chart-display e-card">
                <div className="chart-options">
                    <DataExport data={dataForExport} reportTitle={reportTitle}/>
                    <DropDownListComponent className="duration-dropdown" width="100px" select={onDurationSelected}
                                           dataSource={durationOptions} value={selectedDuration}/>
                    <div className='chart-settings-options'>
                        <div className="switch-container">
                            Digital Twin
                            <SwitchComponent checked={isShowingDigitalTwin} change={onToggleDigitalTwin}/>
                        </div>
                        {isMultiAxisEnabled ?

                            <IconButton onClick={(e) => {
                                {
                                    handleIconMenuClick(e)
                                }
                            }}>
                                <SettingsIcon/>
                            </IconButton>
                            :<div style={{width: '1em'}}/>
                        }
                        <ChartSettingsMenu anchorEl={anchorEl} isOpen={Boolean(anchorEl)}
                                           onClose={() => setAnchorEl(null)} leftSeries={leftSeries}
                                           rightSeries={rightSeries} alerts={alertsData.data}
                                           onLeftSeriesChanged={(leftVariables => setLeftSeries(leftVariables))}
                                           onRightSeriesChanged={(rightVariables) => setRightSeries(rightVariables)}
                                           onAlertsSelectedForOverlay={onPlotAlerts}
                                           onOverlayShutdownsChanged={setIsOverlayShutdowns}
                                           onOverlayWarningsChanged={setIsOverlayWarnings}/>
                    </div>
                </div>
                {selectedDuration === "Custom" ?

                    <div className="custom-date-inputs">
                        <span>Start Date:</span>
                        <DateTimePickerComponent width="200px" showClearButton={false} allowEdit={false}
                                                 openOnFocus={true}
                                                 value={startDate} change={onStartDateChanged}/>
                        <span className="right-item">End Date:</span>
                        <DateTimePickerComponent width="200px" showClearButton={false} allowEdit={false}
                                                 openOnFocus={true}
                                                 value={endDate} change={onEndDateChanged}/>
                    </div>
                    : ""
                }
                <div className="chart-wrapper">
                    {variablesWithData.isLoading ?
                        <ChartSkeleton/>
                        : <LineChartMultiYAxis
                            leftAxisVariables={leftSeries ?? []}
                            rightAxisVariables={rightSeries ?? []}
                            showDigitalTwin={isShowingDigitalTwin}
                            alerts={alertsToOverlay} showMark={false}/>
                    }
                </div>
            </div>
        </div>
    );
}