import React from 'react';
import {StatusIcon} from "../StatusIcon/StatusIcon";
import {CompressorStatusCode} from "../../autogenerate/api.generated.clients";
import {Typography} from "@mui/material";

function ValueNotReceivedMessage() {
    return (
        <span style={{display: 'flex', alignItems: 'center', gap: '1em', margin: '.5em'}}>
            <span style={{height: '1.5em', width: '1.5em'}}>
                <StatusIcon statusCode={CompressorStatusCode.ActiveWarning} isLoading={false}/>
            </span>
            <Typography sx={{flex: '1'}}>Value not received</Typography>
        </span>
    );
}

export default ValueNotReceivedMessage;