import {useCompressorVariableNames} from "../../../../hooks/Compressor/Reports/Charting/useCompressorVariableNames";
import React, {useState} from "react";
import {ChartingVariable} from "../../../../autogenerate/api.generated.clients";
import './chartVariableGrid.scss'
import {GridSkeleton} from "../../../../components/Skeletons/GridSkeleton";
import {Button, Chip, List, ListItemButton, TextField} from "@mui/material";

interface ChartVariableGridProps {
    frameId: string,
    onSubmit?: Function | undefined
}

export function ChartVariableGrid(this: any, props: ChartVariableGridProps) {
    const {frameId} = props;
    const {data, isLoading, error} = useCompressorVariableNames(frameId);
    const [selected, updateSelected] = useState<ChartingVariable[]>([]);
    const [searchText, setSearchText] = useState<string | null>();

    const onChartVariableClicked = (chartingVariable: ChartingVariable) => {
        if (!selected.includes(chartingVariable))
            updateSelected([...selected, chartingVariable])
    }

    const onSelectedChartVariableClicked = (chartingVariable: ChartingVariable) => {
        const newSelectedChartVariables = selected.filter(cv => cv !== chartingVariable)
        updateSelected(newSelectedChartVariables)
    }

    const onPlotClicked = () => {
        if (props.onSubmit) {
            props.onSubmit(selected);
        }
    }

    const onClearClicked = () => {
        updateSelected([]);
        if (props.onSubmit) {
            props.onSubmit([]);
        }
    }

    const onSearchTextChanged = (event: any) => {
        setSearchText(event.target.value);
    };

    return (
        <div className='variable-display-container'>
            <div className='variable-button-container'>
                <Button variant='contained' onClick={onPlotClicked}>
                    Plot
                </Button>
                <Button variant='outlined' onClick={onClearClicked}>
                    Clear
                </Button>
            </div>

            <div className='variable-search-container'>
                <TextField
                    size='small'
                    className='search-input'
                    label="Search variables"
                    onChange={onSearchTextChanged}
                    InputProps={{
                        type: 'search',
                    }}
                    fullWidth={true}
                />
            </div>

            <div className='variable-chip-display'>
                {
                    selected?.map((selectedChartVariable) => {
                        return (
                            <Chip key={selectedChartVariable.ascPropertyName}
                                  sx={{
                                      margin: '3px',
                                      height: 'auto',
                                      '& .MuiChip-label': {
                                          display: 'block',
                                          whiteSpace: 'normal',
                                      },
                                  }}
                                  label={selectedChartVariable.displayName}
                                  onClick={() => onSelectedChartVariableClicked(selectedChartVariable)}
                                  onDelete={() => onSelectedChartVariableClicked(selectedChartVariable)}
                                  color='primary'
                                  variant='outlined'/>
                        );
                    })
                }
            </div>

            <div className='variable-list'>
                <List>
                    {
                        isLoading ?
                            <div style={{width: "100%"}}>
                                <GridSkeleton columns={1} rows={30}/>
                            </div>

                            :

                            data?.filter(cv => !searchText || cv.displayName.toLowerCase().includes(searchText.toLowerCase()))
                                .map((chartVariable) =>
                                    <ListItemButton key={chartVariable.ascPropertyName} divider={true}
                                                    onClick={() => onChartVariableClicked(chartVariable)}>
                                        {chartVariable.displayName}
                                    </ListItemButton>
                                )}
                </List>
            </div>
        </div>
    )
}