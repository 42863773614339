import React, {useEffect, useState} from 'react';
import {FaultRepositoryEntry, FaultRepositoryGenericFaultGroup} from "../../../autogenerate/api.generated.clients";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Divider,
    Link,
    List,
    ListItemButton,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import './faultList.scss'
import ExpandMore from "@mui/icons-material/ExpandMore";

export interface FaultListProps {
    data: FaultRepositoryGenericFaultGroup[],
    initialFaultId: string
}

function FaultList(props: FaultListProps) {
    const {data, initialFaultId} = props;
    const [searchText, setSearchText] = useState('');
    const [selectedFaultId, setSelectedFaultId] = useState(initialFaultId)
    const [selectedFault, setSelectedFault] = useState({} as FaultRepositoryEntry)

    useEffect(() => {
        let flattenedFaults = flattenListOfFaults(data);
        let selectedFault = flattenedFaults?.filter((fault) => fault.ascFaultId.toString() === selectedFaultId)
        if (selectedFault && selectedFault.length > 0) {
            setSelectedFault(selectedFault[0])
        }
    }, [selectedFaultId]);

    const isSelected = (faultId: number) => selectedFaultId == faultId.toString();

    const handleRowClick = (event: React.MouseEvent<unknown>, faultId: string) => {
        if (faultId !== selectedFaultId) {
            setSelectedFaultId(faultId);
        }
    }
    
    const isExpanded = (genericFault : FaultRepositoryGenericFaultGroup) => {
        if(genericFault.faults.find((fault) => fault.ascFaultId.toString() === selectedFaultId))
        {
            return true
        }
        return false
    } 

    const handleSearchEntry = (searchedVal: string) => {
        setSearchText(searchedVal.toLowerCase());
    }

    return (
        <Card className="fault-trouble-shooting-container">
            <div className="fault-list-container">
                <TextField
                    size='small'
                    className='search-bar'
                    label="Search faults"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleSearchEntry(event.target.value)
                    }}
                    InputProps={{
                        type: 'search',
                    }}
                />
                <List className='fault-list'>
                    {data.filter((genericFault) => searchText === '' || genericFault.genericDescription.toLowerCase().includes(searchText) || genericFault.faults.find((fault) => fault.faultDescription.toLowerCase().includes(searchText)))
                        .map((item) => {
                            return (
                                <Accordion defaultExpanded={isExpanded(item)} key={item.genericDescription}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>} >{item.genericDescription}</AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            {item.faults.filter((specificFault) => 
                                                searchText === '' || specificFault.faultDescription.toLowerCase().includes(searchText)
                                            )
                                                .map((fault) => {
                                                    return (
                                                        <ListItemButton
                                                            autoFocus={isSelected(fault.ascFaultId)}
                                                            onClick={(event) => handleRowClick(event, fault.ascFaultId.toString())}
                                                            selected={isSelected(fault.ascFaultId)}
                                                            key={fault.faultDescription}>{fault.faultDescription}</ListItemButton>
                                                    )
                                                })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                </List>
            </div>
            <Divider orientation="vertical"/>
            {!(selectedFault == {} as FaultRepositoryEntry) ?
                <div className="fault-troubleshooting-detail-container">
                    <Typography variant="h2">What's Wrong</Typography>
                    <Typography className="detail">{selectedFault.faultTroubleShootingDescription}</Typography>
                    <Divider/>
                    <Typography variant="h2">Corresponding Setpoint</Typography>
                    <Typography className="detail">{`Name:  ${selectedFault.setPointName}`}</Typography>
                    <Typography className="detail">{`Description:  ${selectedFault.setPointDescription}`}</Typography>
                    <Divider/>
                    <Typography variant="h2">Where to start</Typography>
                    <List className="detail">
                        {selectedFault.startingPoints?.map((startingPoint) => {
                            return (
                                <ListItemText key={startingPoint}>{startingPoint}</ListItemText>
                            )
                        })}
                    </List>
                    <Divider/>
                    <Typography variant="h2">How to fix it</Typography>
                    <List className="detail">
                        {selectedFault.fixes?.map((fix) => {
                            return (
                                <ListItemText key={fix}>{fix}</ListItemText>
                            )
                        })}
                    </List>
                    <Divider/>
                    <Typography variant="h2">Additional Ariel Documentation</Typography>
                    <Link target={'_blank'} className="detail" href="https://members.arielcorp.com/docs/en/manuals/asc-firmware/compatibility-index.html">ER-135-4</Link>
                </div>
                : ''}
        </Card>
    );
}

function flattenListOfFaults(list: FaultRepositoryGenericFaultGroup[]) {
    let flattenedList: FaultRepositoryEntry[] = [];
    list.forEach((genericFault) => {
        flattenedList = flattenedList.concat(genericFault.faults)
    });
    return flattenedList;
}

export default FaultList;