import React from 'react';
import {useGetIsArielEmployee} from "../../hooks/Organization/useGetIsArielEmployee";
import {AppBarComponent} from "@syncfusion/ej2-react-navigations";
import {Button, Typography} from "@mui/material";

interface AuthorizationLoaderProps {
    children: React.ReactNode
}

function AuthorizationChecker(props: AuthorizationLoaderProps) {
    const {children} = props;
    const {data, isLoading, error} = useGetIsArielEmployee();

    if (error && (error as any).status == 403) {
        return (
            <div style={{height: '100%'}}>
                <AppBarComponent colorMode="Primary" id="main-appbar"/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1em',
                    gap: '1em'
                }}>
                    <Typography>
                        You are not authorized to use Ariel Fleet Manager
                    </Typography>
                    <a href='https://www.arielcorp.com/links/cKLO'>
                        <Button variant={'contained'}>Request Access</Button>
                    </a>
                </div>
            </div>
        );
    }

    return (
        <div>
            {children}
        </div>
    );
}

export default AuthorizationChecker;