import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    TextField
} from "@mui/material";
import {useDeleteCompressorOrgAssociation} from "../../hooks/Organization/useDeleteCompressorOrgAssociation";
import {useState} from "react";

export interface RemoveFrameDialogProps {
    orgId: string | undefined,
    orgFrameSns: string[] | null,
    isDialogOpen: boolean,
    toggleIsDialogOpen: Function
    handleFrameDelete: Function
}
export function RemoveFrameDialog(props: RemoveFrameDialogProps) {
    const {orgId, orgFrameSns, isDialogOpen, toggleIsDialogOpen, handleFrameDelete} = props;
    const [framesToDelete, setFramesToDelete] = useState<string[]>([] as string[]);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [searchText, setSearchText] = useState<string>("");
    const deleteCompressorOrgAssociation = useDeleteCompressorOrgAssociation();

    const handleDialogClose = () => {
        toggleIsDialogOpen();
    }

    const handleFrameDialogFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    }
    const onDialogSubmit = async () => {
        if(framesToDelete.length > 0) {
            let frameSerialNumber = framesToDelete[framesToDelete.length - 1];
            const organizationId = orgId ? parseInt(orgId) : 0;
            let isDeleteSuccess = await deleteCompressorOrgAssociation.deleteCompressorOrganizationAssociation(frameSerialNumber, organizationId);
            setFramesToDelete([] as string[]);
            handleFrameDelete(isDeleteSuccess, `Successfully removed Frame(s) from ${orgId}`);
        }
        handleDialogClose();
    }

    const handleFrameSelectionClick = async (frameSerialNumber: string, event: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
        let frames = framesToDelete.slice();
        frames.push(frameSerialNumber);
        setFramesToDelete(frames);
        setSelectedIndex(index);
    }

    return (
        <Dialog
            open={isDialogOpen}
            maxWidth={"sm"}
            fullWidth={true}
            onClose={handleDialogClose}
        >
            <DialogTitle>
                Remove Frame
            </DialogTitle>
            <DialogContent>
                <>
                    <Box
                        component={"form"}
                        noValidate
                        autoComplete={"off"}
                    >
                        <TextField
                            id={"filter-org-compressors"}
                            label={"Filter Compressors"}
                            variant={"outlined"}
                            margin={"normal"}
                            fullWidth
                            value={searchText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {handleFrameDialogFilter(event)}}
                            InputProps={{
                                type:'search'
                            }}
                        />
                    </Box>
                    <List>
                        {
                        orgFrameSns?.length ?
                            orgFrameSns
                                .filter(frameSerialNumber => !searchText || frameSerialNumber.toLowerCase().includes(searchText.toLowerCase()))
                                .map((frameSerialNumber, index) => (
                                <ListItem disableGutters key={frameSerialNumber}>
                                    <ListItemButton
                                        selected={selectedIndex === index}
                                        onClick={(event) => {handleFrameSelectionClick(frameSerialNumber, event, index)}}
                                    >
                                        {frameSerialNumber}
                                    </ListItemButton>
                                </ListItem>
                            ))
                        :
                            "No associated frames for organization."
                        }
                    </List>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogSubmit} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}