import {Client, Compressor} from "../../autogenerate/api.generated.clients";

export function useToggleIsFavorite() {
    const toggleIsFavorite = (compressor: Compressor) => {
        if (!compressor.isFavorite) {
            new Client().favorites_AddFavorite(compressor.frameSerialNumber);
        } else {
            new Client().favorites_DeleteFavorite(compressor.frameSerialNumber);
        }
        compressor.isFavorite = !compressor.isFavorite;
    }

    return {toggleIsFavorite};
}
