export const getOneDayAgo = (): Date => {
    const oneDayAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);

    return oneDayAgo;
}

export const getOneMonthAgo = (): Date => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return oneMonthAgo;
}

export const getHoursAgo = (hours: number): Date => {
    const hoursAgo = new Date();
    hoursAgo.setHours(hoursAgo.getHours() - hours);

    return hoursAgo;
}

export const getDaysAgo = (days: number): Date => {
    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - days);

    return daysAgo;
}


export const minuteDifference = function (date1: Date, date2: Date): number {
    const diffTime: number = Math.abs(date2.getTime() - date1.getTime());
    const minutes = diffTime / 60000;
    return minutes;
}

export const convertDateStringToLocalTime = function (date : string) {
    return new Date(date).toLocaleDateString('en-us', {
        hour12: true,
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    })
}